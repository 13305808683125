import React, { useContext, FC } from 'react';
import { SmartEditContext } from 'components/SmartEditHandling';

export interface WithSmartEditProps {
  /** content catalog version */
  catalogVersion?: string | null;
  /** content catalog name */
  contentCatalog?: string | null;
  /** cms component type */
  restType?: string | null;
  /** determines whether SmartEdit editor needs to be rendered around component or not */
  smartEditAware?: boolean;
  /** id of the cms component */
  uid?: string | null;
  /** uuid of the cms component */
  uuid?: string | null;
  /** components received in case there are any */
  components?: unknown[];
}

const WithSmartEdit = <P extends WithSmartEditProps>(
  WrappedComponent: React.ComponentType<P>,
): FC<P> => {
  const Wrapped: FC<P> = (props) => {
    const {
      catalogVersion = null,
      contentCatalog = null,
      restType = null,
      smartEditAware = false,
      uid = null,
      uuid = null,
    } = props;

    const { isInSmartEdit } = useContext(SmartEditContext);

    /** if in SmartEdit context and if editor for component should be rendered */
    return isInSmartEdit && smartEditAware ? (
      <div
        className="smartEditComponent"
        data-smartedit-component-type={restType}
        data-smartedit-component-id={uid}
        data-smartedit-component-uuid={uuid}
        data-smartedit-catalog-version-uuid={`${contentCatalog}/${catalogVersion}`}
      >
        <WrappedComponent {...props} />
      </div>
    ) : (
      <WrappedComponent {...props} />
    );
  };

  return Wrapped;
};

export default WithSmartEdit;
