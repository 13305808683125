import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ComponentMapper from 'cms/ComponentMapper';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import styles from 'cms/CmsNavigationNodeComponentContainer/CmsNavigationNodeComponentContainer.scss';
import { Heading } from '@xxxlgroup/hydra-ui-components';
import SkipSection from 'components/SkipSection';
import useMessage from 'components/Message/useMessage';

const CmsNavigationNodeComponentContainer = ({ title, navigationNodes }) => {
  const section = useMessage('accessibility.skipSection.list', {}, true);

  const nodes = useMemo(() => {
    const nodesToShow = navigationNodes?.slice(0, -1).concat({
      ...navigationNodes[navigationNodes.length - 1],
      collapsibleClass: styles.collapsibleClass,
    });

    return nodesToShow
      ?.map((node) => <ComponentMapper key={node.name} content={{ ...node, key: node.name }} />)
      .splice(0, 4);
  }, [navigationNodes]);

  return (
    <SkipSection id={nodes?.[0].key} section={section} title={title} spacing="small">
      {title && (
        <Heading className={styles.containerTitle} level={3}>
          {title}
        </Heading>
      )}
      <div className={styles.nodeWrapper}>
        {nodes?.map((node, idx) => {
          const key = idx + 1;
          return (
            <div key={key} className={styles.singleNode}>
              {node}
            </div>
          );
        })}
      </div>
    </SkipSection>
  );
};

CmsNavigationNodeComponentContainer.propTypes = {
  /** title of the container */
  title: PropTypes.string,
  /** node components inside container */
  navigationNodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

CmsNavigationNodeComponentContainer.defaultProps = {
  title: '',
};
export default WithSmartEdit(CmsNavigationNodeComponentContainer);
