import React, { useCallback, useEffect, useState } from 'react';
import WithSmartEdit from 'cms/WithSmartEdit';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import track from 'react-tracking';
import { Tabs, Tab } from '@xxxlgroup/hydra-ui-components';
import ComponentMapper from 'cms/ComponentMapper';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import styles from 'cms/CmsTabsComponentContainer/CmsTabsComponentContainer.scss';

import * as icons from '@xxxlgroup/hydra-icons';

const getTabIcon = (icon) => (Object.keys(icons).includes(icon) ? icons[icon] : '');

const getHash = () => window.location.hash.substring(1);

const isA11yHash = (hash) => hash.includes('skip-section');

const componentType = 'CmsTabsComponentContainer';

export const CmsTabsComponentContainer = (props) => {
  const { cmsPageCode, components, activeTabIndex } = props;

  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(activeTabIndex);
  const [anchorId, setAnchor] = useState(null);

  // Set active tab on very first load
  useEffect(() => {
    const hash = location.hash.substring(1);
    // SkipSection creates a hash to jump over certain components. We don't want to use that hashes as an ID here
    const anchoredTabIndex = components?.findIndex((tab) => tab.anchorId === hash);
    const anchoredComponentTabIndex = components?.findIndex((tab) =>
      tab.components?.find((comp) => comp.anchorId === hash),
    );

    if (hash && !isA11yHash(hash) && anchoredTabIndex > -1) {
      setActiveIndex(anchoredTabIndex);
      setAnchor(hash);
    }

    if (hash && anchoredComponentTabIndex > -1) {
      setActiveIndex(anchoredComponentTabIndex);
    }
  }, [components, location.hash]);

  const onPopState = useCallback(() => {
    const hash = getHash();

    if (hash && !isA11yHash(hash)) {
      const anchoredTabIndex = components?.findIndex((tab) => tab.anchorId === hash);
      anchoredTabIndex > -1 && setActiveIndex(anchoredTabIndex);
      setAnchor(hash);
    }
  }, [components]);

  // Process BACK button click
  useEffect(() => {
    window.addEventListener('popstate', onPopState);
    return () => window.removeEventListener('popstate', onPopState);
  }, [components, onPopState]);

  const changeHash = useCallback(
    (index) => {
      const tabAnchorId = components[index]?.anchorId;
      const hash = getHash();

      // Setting hash should not trigger rerender. Using native history API here
      if (tabAnchorId && tabAnchorId !== hash) {
        window.history.pushState(null, null, `#${tabAnchorId}`);
      }

      // Don't push multiple urls with empty hash
      if (hash && !tabAnchorId) {
        window.history.pushState(null, null, location.pathname);
      }
    },
    [components, location.pathname],
  );

  const tracking = useTracking(props, componentType);

  const handleClick = useCallback(
    (tabTitle) => (event) => {
      tracking(event, { tabTitle });
      changeHash(event.target.dataset?.index);
    },
    [changeHash, tracking],
  );

  const handleHover = useCallback(
    (event) => {
      tracking(event);
    },
    [tracking],
  );

  return (
    <Tabs activeIndex={activeIndex} contentClassName={styles.cmsTabs} anchorId={anchorId}>
      {components?.map((comp) => (
        <Tab
          data-purpose={`${componentType}.tabs.${comp.uid}`}
          glyph={getTabIcon(comp.iconName)}
          onClick={handleClick(comp.tabTitle)}
          onFocus={handleHover}
          onMouseEnter={handleHover}
          title={comp.tabTitle}
          key={comp.uid}
        >
          <ComponentMapper content={{ ...comp, cmsPageCode }} />
        </Tab>
      ))}
    </Tabs>
  );
};

CmsTabsComponentContainer.propTypes = {
  /** Array of Tab components that will be rendered  */
  components: PropTypes.arrayOf(
    PropTypes.shape({
      /** named anchor for tab */
      anchorId: PropTypes.string,
    }),
  ).isRequired,
  /** Index of the selected Tab component  */
  activeTabIndex: PropTypes.number,
  /** Code for cms page  */
  cmsPageCode: PropTypes.string.isRequired,
};

CmsTabsComponentContainer.defaultProps = {
  activeTabIndex: 0,
};

export default track(tagComponent(componentType))(WithSmartEdit(CmsTabsComponentContainer));
