import React, { FC, ReactNode } from 'react';
import { Heading } from '@xxxlgroup/hydra-ui-components';
import ProductSlider from 'components/ProductSlider';
import ProductTile from 'components/ProductTile';
import {
  CampaingProducts,
  RepresentationTypes,
} from 'cms/CmsProductCellContainer/CmsProductCellContainer.types';
import styles from 'cms/CmsProductCellContainer/CmsProductCellContainer.scss';

interface RepresentationSliderProps {
  /** Creates a named anchor link to this component. */
  anchorId: string;
  /** @ignore */
  className?: string;
  /** Code for cms page  */
  code?: string;
  /** Override the default image sizes for the ProductCard component */
  imageSizes?: string;
  /** Sets the id for the component */
  id: string;
  /** Overlay of the last ProductTile */
  lastItemOverlay?: ReactNode;
  /** representation type (List or on of the three slider options: defaultSlider, extendedSlider or imageSlider) */
  representationType: RepresentationTypes;
  /** Determines whether to show the heading or not */
  isHeadingShown?: boolean;
  /** Sets the title of the slider's heading */
  sliderTitle: string;
  /** Products array with single products */
  products: Partial<CampaingProducts>[];
}

const RepresentationSlider: FC<RepresentationSliderProps> = ({
  anchorId,
  code,
  className,
  id,
  imageSizes,
  lastItemOverlay,
  representationType,
  sliderTitle,
  products,
  isHeadingShown,
}) => (
  <ProductSlider
    id={id}
    className={className}
    headingTitle={sliderTitle}
    lastItemOverlay={lastItemOverlay}
    heading={
      sliderTitle &&
      isHeadingShown && (
        <Heading anchorId={anchorId} content={sliderTitle} SEO="h2" level={3} noMargin />
      )
    }
    representationStyle={representationType}
  >
    {products
      .filter((product) => product.code)
      .map((item, index, array) => (
        <ProductTile
          {...item}
          className={styles.productTileGrid}
          id={`${id}-${item.id}`}
          imageSizes={imageSizes}
          key={item.id}
          provider={item.provider ?? ''}
          recommendationId={item.recommendationId}
          representationStyle={representationType}
          sliderId={id}
          sliderItemPosition={index}
          sliderLength={array.length}
          sliderName={code}
          widgetId={item.widgetId ?? ''}
        />
      ))}
  </ProductSlider>
);

export default RepresentationSlider;
