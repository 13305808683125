import React, { FC } from 'react';
import classnames from 'classnames';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import track from 'react-tracking';
import { Carousel } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import ComponentMapper from 'cms/ComponentMapper';
import useMessage from 'components/Message/useMessage';
import { tagComponent } from 'utils/tracking/tracking';

import styles from 'cms/CmsImageSliderComponent/CmsImageSliderComponent.scss';

interface CmsImageSliderComponentProps {
  /** Enable arrows in carousel */
  arrows?: boolean;
  /** Enables autoplay */
  autoplay?: boolean;
  /** Includes all sub components  */
  components?: { id?: string }[];
  /** Sets the id for the component */
  id: string | null;
  /** Renders dot-indicators to show the amount of available slides */
  indicators?: boolean;
}

const CmsImageSliderComponent: FC<CmsImageSliderComponentProps> = (props) => {
  const { id, components, autoplay = false, indicators = false, arrows = false } = props;

  const [next, previous, goTo] = useMessage([
    'wxs.slider.arrow.nextPicture',
    'wxs.slider.arrow.previousPicture',
    'wxs.slider.goto',
  ]);

  if (components && isArrayEmpty(components)) {
    return null;
  }

  return (
    <Carousel
      data-testid="cms.imageSlider"
      sneakPeekDisabled
      autoPlay={autoplay}
      key={id}
      animationSpeed="slow"
      fullWidth={indicators}
      isLooped={indicators}
      className={styles.slider}
      classNameContainer={styles.container}
      classNameArrow={classnames(styles.arrows, !arrows && styles.hide)}
      arrowAttributes={{
        size: 'small',
        onArrowClick: () => {},
      }}
      i18n={{
        next,
        previous,
        goTo,
      }}
    >
      {components?.map((component) => (
        <ComponentMapper key={`cm-${component.id}`} content={{ ...component }} />
      ))}
    </Carousel>
  );
};

export default track(tagComponent('CmsImageSliderComponent'))(
  WithSmartEdit(CmsImageSliderComponent),
);
