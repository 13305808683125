/* eslint-disable react/boolean-prop-naming */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import { Teaser, Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { mail, phone } from '@xxxlgroup/hydra-icons';
import HeroButton from 'cms/CmsHeroComponent/components/Button';
import Promotion from 'cms/CmsHeroComponent/components/Promotion';
import Stamp from 'cms/CmsHeroComponent/components/Stamp';
import { CmsHeroComponentContext } from 'cms/CmsHeroComponent/components/HeroProvider/HeroProvider';
import Link from 'components/WebshopLink';
import { Types } from 'types';
import classnames from 'classnames';
import configurationProps from 'cms/CmsHeroComponent/configuration/style.config';
import WithImpressionTracker from 'components/WithImpressionTracker';
import track from 'react-tracking';
import { getLevel, SMALLEST_SEO } from 'utils/cmsUtils/cmsUtils';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import styles from 'cms/CmsHeroComponent/CmsHeroComponent.scss';

const componentType = 'CmsHeroComponent';

const renderLinkWithIcon = (icon) => (
  <HydraLink glyphBefore={icon} layout="block" typography="regular" theme="mist" />
);

const CmsHeroComponent = (props) => {
  const {
    aboveTheFold,
    backgroundColor,
    buttonType,
    contactEmail,
    contactPhone,
    colorVariant,
    enableHeadlineBackground,
    enableSubtitleBackground,
    headline,
    headlineSubtitleBackgroundOption,
    headingTag,
    heroComponentVariant,
    id,
    index,
    invertedButtonStyle,
    informationText,
    link,
    mediaItems,
    overlay,
    promotion1,
    promotion2,
    subtitle,
    stamp,
  } = props;
  const tracking = useTracking(props, componentType);
  const seoTag = `h${getLevel(headingTag, SMALLEST_SEO)}`;

  const customStyles = ['BANNER'];
  const customVariants = ['BANNER'];
  const hasCustomStyles = customStyles.includes(heroComponentVariant);
  const isCustomVariant = customVariants.includes(heroComponentVariant);
  const desktopMedia = mediaItems?.find((media) => media.format === 'desktop');
  const mobileMedia = mediaItems?.find((media) => media.format === 'mobile');
  const { isDesktopView } = useContext(CmsHeroComponentContext);

  const selectMediaFormat = () => {
    const isVideoIncluded = desktopMedia?.fileType === 'VIDEO' || mobileMedia?.fileType === 'VIDEO';
    if (isVideoIncluded) {
      return isDesktopView ? desktopMedia : mobileMedia;
    }
    return {
      mobile: mobileMedia,
      desktop: desktopMedia,
      fileType: 'MEDIA_CONTAINER',
    };
  };

  const showButton = link?.url;

  const buttonObj = {
    ...(showButton && {
      style: buttonType?.toLowerCase(),
      target: link?.target,
      text: link?.linkName,
      url: link?.url,
      isInverted: invertedButtonStyle,
    }),
  };

  const handleClick = (event) => {
    tracking(event);
  };

  const handleHover = (event) => {
    tracking(event);
  };

  const promotions = [promotion1, promotion2].filter(Boolean);
  const colorTheme = colorVariant?.toLowerCase();
  const heroTheme = heroComponentVariant?.toLowerCase();
  const overlayValue = overlay ? overlay?.toLowerCase() : 'no_overlay';

  const renderPromotions = () =>
    promotions.length > 0 && (
      <div className={classnames(styles.promotions, styles[colorTheme])}>
        {promotions.map((element) => (
          <Promotion key={`promotion-${element}`} element={element} />
        ))}
      </div>
    );

  const headlineWithoutPTag = headline?.replace(/<\/?p[^>]*>/g, '');
  const fetchPriority = aboveTheFold && index === 0 ? 'high' : undefined;
  const loading = aboveTheFold && index === 0 ? 'eager' : 'lazy';

  return (
    <div className={styles.heroWrapper}>
      <Teaser
        additionalContent={renderPromotions()}
        bodyClassName={styles[heroTheme]}
        button={
          showButton && (
            <HeroButton
              button={buttonObj}
              onClick={handleClick}
              onHover={handleHover}
              className={styles.button}
            />
          )
        }
        className={classnames(styles[colorTheme], styles[`overlay-${overlayValue}`], {
          [styles[backgroundColor?.toLowerCase()]]: !(desktopMedia || mobileMedia),
          [styles.cmsHeroComponent]: !hasCustomStyles,
          [styles[heroTheme]]: hasCustomStyles,
          [styles.noText]: !headline && !subtitle,
          [styles.hasStamp]: stamp,
        })}
        contentClassName={styles.contentWrapper}
        data-purpose="teaser.section"
        fetchPriority={fetchPriority}
        headingSeoLevel={seoTag}
        informationalText={informationText}
        contactEmail={
          contactEmail && (
            <Link
              as={renderLinkWithIcon(mail)}
              className={styles.contactLink}
              href={`mailto:${contactEmail}`}
              target="_blank"
            >
              {contactEmail}
            </Link>
          )
        }
        contactPhone={
          contactPhone && (
            <Link
              as={renderLinkWithIcon(phone)}
              className={styles.contactLink}
              href={`tel:${contactPhone}`}
              target="_blank"
            >
              {contactPhone}
            </Link>
          )
        }
        key={id}
        link={
          <Link
            href={link?.url}
            target={link?.target}
            onClick={handleClick}
            data-purpose="teaser.link"
            ariaHidden
            tabIndex="-1"
          />
        }
        loading={loading}
        media={selectMediaFormat()}
        mediaClassName={styles.media}
        subTitle={subtitle}
        textTheme={headlineSubtitleBackgroundOption?.toLowerCase()}
        title={headlineWithoutPTag}
        variant={isCustomVariant ? heroTheme : colorTheme}
        withSubtitleBackground={enableSubtitleBackground}
        withTitleBackground={enableHeadlineBackground}
      >
        {stamp && (
          <Stamp
            stamp={stamp}
            loading={loading}
            variant="default"
            className={styles.stampWrapper}
          />
        )}
      </Teaser>
    </div>
  );
};

CmsHeroComponent.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  aboveTheFold: PropTypes.bool,
  /** Background color for the component if image/video is not set */
  backgroundColor: PropTypes.oneOf(['PRIMARY', 'primary', 'PROMO1', 'PROMO2']),
  /** Style of the button */
  buttonType: PropTypes.string,
  /** contact email */
  contactEmail: PropTypes.string,
  /** contact phone */
  contactPhone: PropTypes.string,
  /** Sets text variant option */
  colorVariant: PropTypes.oneOf(['DARK', 'LIGHT']),
  /** Defines if a title has a background  */
  enableHeadlineBackground: PropTypes.bool,
  /** Defines if a subtitle has a background  */
  enableSubtitleBackground: PropTypes.bool,
  /** Headline of the item  */
  headline: PropTypes.string,
  /** Contains list of available Title / Subtitle background variants */
  headlineSubtitleBackgroundOption: PropTypes.oneOf([
    'PRIMARY',
    'SECONDARY',
    'LIGHT_SNOW',
    'PROMO1',
    'PROMO2',
    'PROMO3',
    'PROMO4',
    'PROMO5',
  ]),
  /** Tag that should be used for the heading */
  headingTag: PropTypes.oneOf(['H1', 'H2', 'H3', 'H4']),
  /** Variant for the CmsHeroComponent */
  heroComponentVariant: PropTypes.oneOf(['BILLBOARD', 'BANNER', 'PROMOTION', 'STAMPED']),
  /** Sets the id for the component */
  id: PropTypes.string.isRequired,
  /** Flag for inverted styling for button */
  // eslint-disable-next-line react/boolean-prop-naming
  invertedButtonStyle: PropTypes.bool,
  /** information text below button  */
  informationText: PropTypes.string,
  index: PropTypes.number,
  /** CmsLinkComponent */
  link: PropTypes.oneOfType([Types.link]),
  /** Desktop and mobile background image/video for the Hero component */
  mediaItems: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, Types.image])),
  /** Overlay style to increase text-background contrast */
  overlay: PropTypes.oneOf(['NO_OVERLAY', 'DARK_OVERLAY']),
  /** First promotion for Promotion variant */
  promotion1: PropTypes.string,
  /** Second promotion for Promotion variant */
  promotion2: PropTypes.string,
  /** Additional svg/png stamp for the Image Banner */
  stamp: PropTypes.oneOfType([PropTypes.string, Types.image]),
  /** Subtitle of the item  */
  subtitle: PropTypes.string,
};

CmsHeroComponent.defaultProps = {
  contactEmail: null,
  contactPhone: null,
  backgroundColor: configurationProps.backgroundColor,
  buttonType: configurationProps.buttonType,
  colorVariant: 'LIGHT',
  enableHeadlineBackground: false,
  enableSubtitleBackground: false,
  headline: '',
  headlineSubtitleBackgroundOption: 'PRIMARY',
  headingTag: 'H2',
  heroComponentVariant: '',
  invertedButtonStyle: configurationProps.inverted,
  informationText: null,
  link: null,
  mediaItems: [],
  overlay: 'NO_OVERLAY',
  promotion1: null,
  promotion2: null,
  stamp: null,
  subtitle: null,
};

export default track(tagComponent(componentType))(
  WithSmartEdit(WithImpressionTracker(CmsHeroComponent)),
);
