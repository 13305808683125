import PropTypes, { bool } from 'prop-types';
import React from 'react';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import loadable from '@loadable/component';
import useLogger from 'hooks/useLogger';

import CmsCategoryNavigationComponent from 'cms/CmsCategoryNavigationComponent';
import CmsFooterCombinedBoxComponent from 'cms/CmsFooterCombinedBoxComponent';
import CmsNavigationNodeComponent from 'cms/CmsNavigationNodeComponent';
import CmsIconParagraphComponent from 'cms/CmsIconParagraphComponent';
import CmsFooterRibbonComponent from 'cms/CmsFooterRibbonComponent';
import CmsProductCellContainer from 'cms/CmsProductCellContainer';
import CmsParagraphComponent from 'cms/CmsParagraphComponent';
import CmsHeadingComponent from 'cms/CmsHeadingComponent';
import CmsImageComponent from 'cms/CmsImageComponent';
import CmsLinkComponent from 'cms/CmsLinkComponent';
import CmsHeroComponent from 'cms/CmsHeroComponent';
import CmsMainContainer from 'cms/CmsMainContainer';
import CmsSubNavigationContainer from 'cms/CmsSubNavigationContainer';
import CmsSuperBillboardSliderContainer from 'cms/CmsSuperBillboardSliderContainer';
import CmsTextImageComponent from 'cms/CmsTextImageComponent';
import CmsMainNavigationComponent from 'cms/CmsMainNavigationComponent';
import CmsTabComponentContainer from 'cms/CmsTabComponentContainer';
import CmsTabsComponentContainer from 'cms/CmsTabsComponentContainer';
import CmsNavigationNodeComponentContainer from 'cms/CmsNavigationNodeComponentContainer';
import CmsImageSliderComponent from 'cms/CmsImageSliderComponent';
import CmsAccordionsComponentContainer from 'cms/CmsAccordionsComponentContainer';
import CmsTeaserGridContainer from 'cms/CmsTeaserGridContainer';
import CmsFlyoutComponent from 'cms/CmsFlyoutComponent';
import CmsIntegrationComponent from 'cms/CmsIntegrationComponent';
import CmsAccordionComponentContainer from 'cms/CmsAccordionComponentContainer';

/* Preload commonly used components */
const preLoad = {
  CmsCategoryNavigationComponent,
  CmsFooterCombinedBoxComponent,
  CmsNavigationNodeComponent,
  CmsIconParagraphComponent,
  CmsFooterRibbonComponent,
  CmsProductCellContainer,
  CmsParagraphComponent,
  CmsHeadingComponent,
  CmsImageComponent,
  CmsLinkComponent,
  CmsHeroComponent,
  CmsMainContainer,
  CmsSuperBillboardSliderContainer,
  CmsTextImageComponent,
  CmsMainNavigationComponent,
  CmsSubNavigationContainer,
  CmsTabComponentContainer,
  CmsTabsComponentContainer,
  CmsNavigationNodeComponentContainer,
  CmsImageSliderComponent,
  CmsAccordionsComponentContainer,
  CmsTeaserGridContainer,
  CmsFlyoutComponent,
  CmsIntegrationComponent,
  CmsAccordionComponentContainer,
};

/**
 * This mapper maps an object to a React component.
 */
export const ComponentMapper = ({ content, aboveTheFoldOverride }) => {
  const logger = useLogger();
  const shouldRestrictComponents = useFeatureFlagsEnabled('restrict.old.components');
  const restrictedComponents = [
    'CmsImageMapComponent',
    'CmsExtendedParagraphComponent',
    'CmsStaffRecommendationComponent',
    'CmsTeaserGridContainer',
  ];
  let MapperComponent = null;

  if (shouldRestrictComponents && restrictedComponents.includes(content.restType)) {
    return null;
  }

  if (!content.restType) {
    logger('Undefined component. restType is null');
    return null;
  }

  if (content.restType in preLoad) {
    MapperComponent = preLoad[content.restType];
    return (
      <MapperComponent
        key={content.id}
        {...content}
        aboveTheFold={aboveTheFoldOverride || content.aboveTheFold}
      />
    );
  }

  MapperComponent = loadable(() =>
    import(`../${content.restType}/index`).catch(() => {
      logger(`${content.restType} does not exist`);
      return { default: () => null };
    }),
  );
  // saving the already loaded components into the preLoad object,
  // then they don't have to be loaded again.
  preLoad[content.restType] = MapperComponent;
  return <MapperComponent key={content.id} {...content} />;
};

ComponentMapper.propTypes = {
  // workaround so the Parent Component overrides the `aboveTheFold` prop for his child`s
  // eslint-disable-next-line react/boolean-prop-naming
  aboveTheFoldOverride: PropTypes.bool,
  content: PropTypes.shape({
    aboveTheFold: bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    restType: PropTypes.string,
    // used as an indication of the current child index of the CmsSuperBillBoardSliderContainer
    index: PropTypes.number,
  }),
};

ComponentMapper.defaultProps = {
  content: {},
  aboveTheFoldOverride: false,
};

export default ComponentMapper;
