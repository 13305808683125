import React from 'react';
import CmsContent from 'components/CmsContent';
import { useRouteMatch } from 'react-router';
import useRoutes from 'hooks/useRoutes';
import useIsModeHeadless from 'hooks/useIsModeHeadless';

const LastSeenProducts = () => {
  const routes = useRoutes();
  const isModeHeadless = useIsModeHeadless();

  const templatesPages = [
    {
      match: useRouteMatch({
        path: routes.cmsPage,
      }),
      templateId: 'singleslotpage',
    },
    {
      match: useRouteMatch({
        path: routes.productDetailPage,
      }),
      templateId: 'productdetail',
    },
    {
      match: useRouteMatch({
        path: routes.categoryPage,
      }),
      templateId: 'categorypage',
    },
    {
      match: true,
      templateId: 'singleslotpage',
    },
  ];
  const { templateId } = templatesPages.find(({ match }) => match);

  if (isModeHeadless) {
    return null;
  }

  return <CmsContent templateId={templateId} templatePosition="lastseenproduct" />;
};

export default LastSeenProducts;
