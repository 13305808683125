import React from 'react';
import PropTypes from 'prop-types';
import Box from 'components/Box';
import Link from 'components/WebshopLink';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import WithSmartEdit from 'cms/WithSmartEdit';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import styles from 'cms/CmsNavigationNodeComponent/CmsNavigationNodeComponent.scss';
import { Accordion, Collapsible, Heading } from '@xxxlgroup/hydra-ui-components';
import classNames from 'classnames';

const CmsNavigationNodeComponent = (props) => {
  const { collapsibleClass, items, title, wrapAfter } = props;
  const isDesktopView = useMediaQuery({ largerThan: 'lg' });

  const tracking = useTracking(props, 'CmsNavigationNodeComponent');
  const handleClick = (listLabel, linkListElement, id, headline) => (event) => {
    tracking(event, {
      headline,
      listLabel,
      linkListElement,
      id,
    });
  };

  const renderLinks = (links) => (
    <Box tag="ul" display="flex" wrap="wrap" direction="column" className={styles.linkList}>
      {links.components.slice(0, wrapAfter).map(
        (link) =>
          link.restType === 'CmsLinkComponent' && (
            <li key={link.url} className={styles.nodeWrapper} data-testid="CmsLinkComponent">
              <Link
                className={styles.link}
                href={link.url}
                target={link.target}
                onClick={handleClick(title, link.linkName, link.uid, title)}
              >
                {link.linkName}
              </Link>
            </li>
          ),
      )}
    </Box>
  );

  const renderList = (links, index) => (
    <div className={styles.sectionList} key={`${links.title}-${index}`}>
      {title && (
        <Heading SEO level="4" className={styles.listLabel}>
          {title}
        </Heading>
      )}
      {renderLinks(links)}
    </div>
  );

  const renderCollapsable = (links) => (
    <Accordion key={links.title}>
      <Collapsible
        className={styles.accordion}
        activator={title}
        activatorClassName={classNames(styles.listLabel, collapsibleClass)}
        contentClassName={classNames(styles.listLabel, collapsibleClass)}
      >
        {renderLinks(links)}
      </Collapsible>
    </Accordion>
  );

  return items?.map((item, index) =>
    isDesktopView ? renderList(item, index) : renderCollapsable(item),
  );
};

CmsNavigationNodeComponent.propTypes = {
  /** Specific class for collapsible */
  collapsibleClass: PropTypes.string,
  /** Array of items to be rendered */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** code from smart edit system */
      code: PropTypes.string,
    }),
  ).isRequired,
  /** tilte of the link list */
  title: PropTypes.string,
  /** Number of the links that will be shown */
  wrapAfter: PropTypes.number,
};

CmsNavigationNodeComponent.defaultProps = {
  title: '',
  wrapAfter: 6,
  collapsibleClass: '',
};

export default track(tagComponent('CmsNavigationNodeComponent'))(
  WithSmartEdit(CmsNavigationNodeComponent),
);
