import React, { useEffect } from 'react';
import {
  HeadingLevel,
  HeadingTextAlign,
} from '@xxxlgroup/hydra-ui-components/dist/common/components/Heading/Heading.types';
import { SEOLevel } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import { Heading } from '@xxxlgroup/hydra-ui-components';
import classnames from 'classnames';
import WithSmartEdit from 'cms/WithSmartEdit';
import Layout from 'components/Layout';
import {
  getLevel,
  MAX_NUMBER_OF_COLUMNS,
  SMALLEST_FONTSIZE,
  SMALLEST_SEO,
} from 'utils/cmsUtils/cmsUtils';
import track from 'react-tracking';
import { useTracking } from 'utils/tracking/hooks';
import { tagComponent } from 'utils/tracking/tracking';
import SkipSection from 'components/SkipSection';
import useMessage from 'components/Message/useMessage';
import ChildCmsComponent from 'cms/CmsMainContainer/components/ChildCmsComponent';
import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';

import styles from 'cms/CmsMainContainer/CmsMainContainer.scss';

const CMS_MAIN_CONTAINER = 'CmsMainContainer';
const CMS_CATEGORY_NAVIGATION_COMPONENT = 'CmsCategoryNavigationComponent';
const CMS_MAIN_NAVIGATION_COMPONENT = 'CmsMainNavigationComponent';
const CMS_NAVIGATION_NODE_COMPONENT = 'CmsNavigationNodeComponent';

export interface DefaultCmsMainContainer {
  // eslint-disable-next-line react/boolean-prop-naming
  aboveTheFold?: boolean;
  /* Creates a named anchor link to this component. */
  anchorId?: string;
  /*   */
  childrenStructureVariant?: 'BLOCK' | 'LIST';
  /* Includes all sub components  */
  components?: any[];
  /* Code for cms page  */
  cmsPageCode?: string;
  /* Font size of the heading text, ranging from H1 to H7 in Backoffice, but only H1 to H4 used on new frontend */
  headingFontSize?: 'HEADLINE1' | 'HEADLINE2' | 'HEADLINE3' | 'HEADLINE4';
  /* Level of the heading tag, for SEO purpose, H1 to H6 and DIV used on old frontend, only H1 to H4 are used on new frontend */
  headingTag?: 'H1' | 'H2' | 'H3' | 'H4';
  /* Text used for the container's heading */
  headingTitle?: string;
  /* Content alignment inside the container */
  horizontalAlignment?: 'LEFT' | 'CENTER';
  /* parent image sizes for the container */
  imageSizes?: string;
  /* Status helper to identify MainContainers inside an outer MainContainer */
  isNestedContainer?: boolean;
  /* Max number of child components that should fit in one row  */
  numberOfColumns: string;
  /* Id to identify section */
  uid: string;
  /* Vertical flow policy */
  verticalComponentAlignment?: 'STACK_TOP' | 'SPACE_BETWEEN' | 'SPACE_AROUND' | 'STRETCH';
}

export const CmsMainContainer = (props: DefaultCmsMainContainer) => {
  const {
    aboveTheFold = false,
    anchorId = undefined,
    childrenStructureVariant = 'BLOCK',
    cmsPageCode = '',
    components = [],
    headingFontSize = 'HEADLINE4',
    headingTag = null,
    headingTitle = undefined,
    horizontalAlignment = 'LEFT',
    isNestedContainer = false,
    verticalComponentAlignment = 'STACK_TOP',
    numberOfColumns,
    uid,
    imageSizes,
  } = props;
  const tracking = useTracking(props, CMS_MAIN_CONTAINER);
  const isRectangularCardsEnabled = useFeatureFlagsEnabled(
    'poseidon.maincontainer.categorycard.rectangle.enabled',
  );
  const isListStructure = childrenStructureVariant === 'LIST';
  const columnsNumber = Number(numberOfColumns);

  useEffect(() => {
    tracking({ rootCategory: headingTitle });
  }, [headingTitle, tracking]);

  const renderHeading = () => {
    const fontSize = headingFontSize
      ? getLevel(headingFontSize, SMALLEST_FONTSIZE)
      : SMALLEST_FONTSIZE;

    const seoLevel = headingTag ? (`h${getLevel(headingTag, SMALLEST_SEO)}` as SEOLevel) : true;

    if (!headingTitle) {
      return null;
    }

    return (
      <Heading
        anchorId={anchorId}
        content={headingTitle}
        level={fontSize as HeadingLevel}
        SEO={seoLevel}
        textAlign={horizontalAlignment.toLowerCase() as HeadingTextAlign}
      />
    );
  };

  const onlyNavComponentsCheck = (component: any) => {
    if (component.restType === CMS_MAIN_CONTAINER) {
      return component.components.some(onlyNavComponentsCheck);
    }

    return (
      component.restType !== CMS_CATEGORY_NAVIGATION_COMPONENT &&
      component.restType !== CMS_MAIN_NAVIGATION_COMPONENT
    );
  };

  const hasOnlyNavComponents = !components.some((component) => onlyNavComponentsCheck(component));

  const isTabletDownFullRowComponent = (component: any) =>
    component.restType === CMS_NAVIGATION_NODE_COMPONENT;

  const marginStyle = isNestedContainer ? 'none' : 'section';
  const className = classnames(
    {
      [styles.nestedMainContainer]: isNestedContainer,
      [styles.verticalStackTop]: verticalComponentAlignment === 'STACK_TOP',
      [styles.verticalSpaceBetween]: verticalComponentAlignment === 'SPACE_BETWEEN',
      [styles.verticalSpaceAround]: verticalComponentAlignment === 'SPACE_AROUND',
      [styles.verticalStretch]: verticalComponentAlignment === 'STRETCH',
      [styles.centered]: horizontalAlignment === 'CENTER',
      [styles.singleColumnAllowed]: hasOnlyNavComponents || isRectangularCardsEnabled,
      [styles.listStructure]: isListStructure,
    },
    styles[`grid-${Math.min(columnsNumber, MAX_NUMBER_OF_COLUMNS)}`],
  );

  const section = useMessage('accessibility.skipSection.list', {}, true);
  return (
    <>
      {renderHeading()}
      <SkipSection
        id={uid}
        section={section}
        spacing={headingTitle ? 'large' : 'small'}
        active={columnsNumber > 2}
        title={headingTitle}
      >
        <Layout
          margin={marginStyle}
          variant="full"
          className={className}
          tag={isListStructure ? 'ul' : 'div'}
        >
          {components?.map((component) => (
            <ChildCmsComponent
              aboveTheFold={aboveTheFold}
              cmsPageCode={cmsPageCode}
              component={component}
              hasOnlyNavComponents={hasOnlyNavComponents}
              imageSizes={imageSizes}
              isFullRowComponent={isTabletDownFullRowComponent(component)}
              isRectangularCardsEnabled={!!isRectangularCardsEnabled}
              key={component.uid}
              numberOfColumns={columnsNumber}
              tag={isListStructure ? 'li' : 'div'}
            />
          ))}
        </Layout>
      </SkipSection>
    </>
  );
};

export default track(tagComponent('CmsMainContainer'))(WithSmartEdit(CmsMainContainer));
