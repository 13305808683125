import React from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import classnames from 'classnames';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { config } from '@xxxlgroup/hydra-config';
import { Image } from '@xxxlgroup/hydra-ui-components';
import * as icons from '@xxxlgroup/hydra-icons';
import Link from 'components/WebshopLink';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import WithImpressionTracker from 'components/WithImpressionTracker/WithImpressionTracker';
import { Types } from 'types';

import styles from 'cms/CmsImageComponent/CmsImageComponent.scss';

const COMPONENT_TYPE = 'CmsImageComponent';

export const CmsImageComponent = (props) => {
  const {
    aspectRatio,
    id,
    iconName,
    image,
    imageSizes,
    isPromotion,
    mediaSource,
    link,
    poseidonLink,
    className,
    useSrcSet,
    width,
    height,
    toggleHeight,
  } = props;

  const tracking = useTracking(props, COMPONENT_TYPE);

  const handleClick = (event) => {
    tracking(event);
  };

  const renderWithLink = (url, target, element) => (
    <div
      key={id}
      className={classnames(
        styles.imageContainer,
        { [styles.iconVariant]: iconName },
        { [styles.imageComponentCropped]: toggleHeight },
        className,
      )}
    >
      <Link
        href={url}
        target={target}
        className={styles.imageLink}
        data-purpose="cms.image.component.link"
        isPromotion={isPromotion}
        onClick={handleClick}
      >
        {element}
      </Link>
    </div>
  );

  const [iconStyle, iconClassName] = pseudoIcon(icons[iconName]);
  const imageElement =
    image && mediaSource === 'MEDIA' ? (
      <Image
        className={styles.img}
        aspectRatio={aspectRatio}
        useSrcSet={useSrcSet}
        sizes={
          imageSizes ||
          `(min-width: ${config.breakpoints.lg}px) ${config.breakpoints.xl}px,
              (min-width: ${config.breakpoints.sm}px) ${config.breakpoints.lg}px,
              100vw`
        }
        source={image}
        height={height}
        width={width}
      />
    ) : (
      iconName && <span className={classnames(styles.icon, iconClassName)} style={iconStyle} />
    );
  if (poseidonLink) {
    return renderWithLink(poseidonLink.url, poseidonLink.target, imageElement);
  }
  if (link) {
    return renderWithLink(link.url, link.target, imageElement);
  }
  return (
    <div
      data-testid="cms.image"
      className={classnames(
        styles.elementWrapper,
        { [styles.iconVariant]: iconName },
        { [styles.imageComponentCropped]: toggleHeight },
        className,
      )}
      key={id}
    >
      {imageElement}
    </div>
  );
};

CmsImageComponent.propTypes = {
  /** Sets image aspect ratio */
  aspectRatio: PropTypes.string,
  /** @ignore */
  className: PropTypes.string,
  /** height of the image */
  height: PropTypes.number,
  /** Sets the id for the component */
  id: PropTypes.string,
  /** Icon to show for mediaSource HYDRA_ICON */
  iconName: PropTypes.string,
  /** Image for the component */
  image: PropTypes.oneOfType([PropTypes.string, Types.image]),
  /** ImageSizes for the component */
  imageSizes: PropTypes.string,
  /** Flag to determine if component is used as a promotion */
  isPromotion: PropTypes.bool,
  /** Source for media that is displayed */
  mediaSource: PropTypes.oneOf(['MEDIA', 'HYDRA_ICON']).isRequired,
  /** Link objet of the image component, for component that might come from old frontend */
  link: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string,
  }),
  /** Poseidon link component */
  poseidonLink: Types.link,
  /** Flag to determine should src be used */
  // eslint-disable-next-line react/boolean-prop-naming
  useSrcSet: PropTypes.bool,
  /** width of the image */
  width: PropTypes.number,
  /** sets the Height smaller */
  // eslint-disable-next-line react/boolean-prop-naming
  toggleHeight: PropTypes.bool,
};

CmsImageComponent.defaultProps = {
  aspectRatio: null,
  className: null,
  height: 0,
  iconName: null,
  id: null,
  image: null,
  imageSizes: null,
  isPromotion: false,
  link: null,
  poseidonLink: null,
  useSrcSet: true,
  width: 0,
  toggleHeight: false,
};

export default track(tagComponent('CmsImageComponent'))(
  WithSmartEdit(WithImpressionTracker(CmsImageComponent)),
);
