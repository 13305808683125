import React, { FC } from 'react';
import RepresentationTileGrid from 'cms/CmsProductCellContainer/components/RepresentationTileGrid';
import { Heading } from '@xxxlgroup/hydra-ui-components';
import Layout from 'components/Layout';
import { CampaingProducts } from 'cms/CmsProductCellContainer/CmsProductCellContainer.types';
import styles from 'cms/CmsProductCellContainer/CmsProductCellContainer.scss';

interface RepresentationGridProps {
  /** Creates a named anchor link to this component. */
  anchorId?: string;
  /** Sets the title of the campaign's heading */
  campaignTitle?: string;
  /** Products array with single products */
  products: CampaingProducts[];
}

const RepresentationGrid: FC<RepresentationGridProps> = ({ anchorId, campaignTitle, products }) => {
  const isTitleEmptyWithoutHTML = campaignTitle?.replace(/<[^>]+>/gi, '');

  return (
    <>
      {campaignTitle && (
        <Heading
          className={styles.mobileHeading}
          noMargin={!isTitleEmptyWithoutHTML && !!anchorId}
          anchorId={anchorId}
          content={campaignTitle}
          SEO="h2"
          level={2}
        />
      )}
      <Layout
        grid="productGrid"
        margin="section"
        className={styles.productGrid}
        data-purpose="listing.productsContainer"
      >
        <RepresentationTileGrid products={products} mode="Grid" />
      </Layout>
    </>
  );
};

export default RepresentationGrid;
