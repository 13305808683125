import React, { FC, MouseEventHandler, MouseEvent, useCallback } from 'react';
import { Button, Heading, Skeleton } from '@xxxlgroup/hydra-ui-components';
import { arrowRight } from '@xxxlgroup/hydra-icons';
import Link from 'components/WebshopLink';
import FHPreviewButton from 'cms/CmsProductCellContainer/components/FHPreviewButton';
import { useQuery } from '@apollo/client';
import useMessage from 'components/Message/useMessage';
import useIsMountedOnCSR from 'hooks/useIsMountedOnCsr';
import {
  getFHCampaignData,
  getRepresentation,
  isShowMoreButtonVisible,
  parseProducts,
} from 'cms/CmsProductCellContainer/utils';
import { CAMPAIGNS_QUERY } from 'cms/CmsProductCellContainer/CmsProductCellContainer.query';
import { TYPES } from 'components/ProductSlider/ProductSlider';
import classnames from 'classnames';
import { getLastItemOverlay, getShowMoreLink } from 'components/ProductSlider/utils';

import {
  CampaingProducts,
  FredhopperCampaignsQueryData,
  FredhopperQueryVariables,
  RepresentationTypes,
} from 'cms/CmsProductCellContainer/CmsProductCellContainer.types';

import styles from 'cms/CmsProductCellContainer/CmsProductCellContainer.scss';

const ASYNC_PROVIDER = 'ASYNC';
const { EXTENDED, LIST, REGULAR } = TYPES;

interface GenericProviderProps {
  anchorId: string;
  campaignId?: string;
  cmsPageCode?: string;
  code?: string;
  id: string;
  imageSizes?: string;
  linkText?: string;
  maxShown?: number;
  minShown: number;
  productCode: string;
  productProvider: string;
  products: CampaingProducts[];
  propsShowLink: boolean;
  recommendationId?: string;
  representationType: RepresentationTypes;
  title: string;
  tracking: (event: MouseEvent) => void;
  url?: string;
}

const GenericProvider: FC<GenericProviderProps> = ({
  anchorId,
  campaignId,
  cmsPageCode,
  code,
  id,
  imageSizes,
  linkText,
  maxShown,
  minShown,
  productCode,
  productProvider,
  products,
  propsShowLink,
  recommendationId,
  representationType,
  title,
  tracking,
  url,
}) => {
  const isMountedOnCSR = useIsMountedOnCSR();
  const productCellContainerLinkText = useMessage('wxs.wcms.productcellcontainer.linktext');

  const handleClick: MouseEventHandler = useCallback(
    (event) => {
      tracking(event);
    },
    [tracking],
  );

  const handleHover: MouseEventHandler = useCallback(
    (event) => {
      tracking(event);
    },
    [tracking],
  );

  const { loading, error, data } = useQuery<FredhopperCampaignsQueryData, FredhopperQueryVariables>(
    CAMPAIGNS_QUERY,
    {
      skip: !isMountedOnCSR || products?.length > 0,
      variables: {
        cmsPageCode: cmsPageCode ?? '',
        include: productProvider === ASYNC_PROVIDER, // used by @include directive to include getCampaigns field when true
        productCode: cmsPageCode === '404' && productProvider === ASYNC_PROVIDER ? '' : productCode,
      },
    },
  );

  if (loading) {
    return <Skeleton className={styles.skeleton} count={1} />;
  }

  if (error) {
    return null;
  }

  const fHCampaignData = getFHCampaignData({
    campaignId,
    data,
    maxShown,
    minShown,
    products: parseProducts(products, recommendationId, campaignId, productProvider),
    propsShowLink,
    title,
    url,
  });

  if (fHCampaignData === null) {
    return null;
  }

  const displayButton = isShowMoreButtonVisible(productProvider, fHCampaignData);
  const sliderType = representationType !== LIST;
  const showMoreLinkText = linkText ?? productCellContainerLinkText;
  const displayShowMoreLink = displayButton && sliderType;
  const lastItemOverlay =
    displayShowMoreLink &&
    getLastItemOverlay(
      showMoreLinkText,
      fHCampaignData.productList.length,
      fHCampaignData.href,
      representationType === EXTENDED,
    );

  return (
    <div
      className={classnames(
        styles[representationType.toLowerCase()],
        { [styles.header]: fHCampaignData.showHeader },
        { [styles.noTitle]: !fHCampaignData.campaignTitle },
        { [styles.hasButton]: fHCampaignData.showLink },
      )}
      key={id}
    >
      <FHPreviewButton fhPreview={fHCampaignData.campaignsData?.fhPreview} />
      <div className={styles.headingContainer}>
        {fHCampaignData.campaignTitle && sliderType && (
          <Heading
            anchorId={anchorId}
            content={fHCampaignData.campaignTitle}
            level={3}
            noMargin={representationType === REGULAR}
            SEO="h2"
          />
        )}
        {displayShowMoreLink &&
          getShowMoreLink(
            showMoreLinkText,
            fHCampaignData.productList.length,
            fHCampaignData.href,
            representationType === EXTENDED,
            handleClick,
            handleHover,
          )}
      </div>
      {fHCampaignData.productList &&
        getRepresentation({
          anchorId,
          code,
          fHCampaignData,
          id,
          imageSizes,
          lastItemOverlay,
          representationType,
        })}
      {displayButton && !sliderType && (
        <Link
          as={<Button glyphAfter={arrowRight} layout="block" size="regular" variant="tertiary" />}
          href={fHCampaignData.href}
          onClick={handleClick}
          onFocus={handleHover}
          onMouseEnter={handleHover}
          target="_self"
        >
          {showMoreLinkText}
        </Link>
      )}
    </div>
  );
};

export default GenericProvider;
