import React, { createContext, useEffect } from 'react';
import ContentBlock from 'components/ContentBlock';
import type { SnackbarApiInstance } from '@xxxlgroup/hydra-ui-components/dist/common/components/Snackbar';
import useDynamicContentService from 'hooks/useDynamicContentService';

export const prevFlexPopups = new Map<string, SnackbarApiInstance | null>();

export const FlexPopupContext = createContext(prevFlexPopups);

const FlexPopupContainer = () => {
  const { slots, loading } = useDynamicContentService();
  const trboId = slots?.filter(({ code }: { code: string }) => code === 'general.flex-popup')?.[0]
    ?.components?.[0]?.id;

  useEffect(() => {
    if (!loading && !prevFlexPopups.has(trboId)) {
      prevFlexPopups.forEach((prevFlexPopups) => prevFlexPopups?.dismiss());
      prevFlexPopups.clear();
    }
  }, [loading, slots, trboId]);

  return (
    <FlexPopupContext.Provider value={prevFlexPopups}>
      <ContentBlock area="general.flex-popup" />
    </FlexPopupContext.Provider>
  );
};

export default FlexPopupContainer;
