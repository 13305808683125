import React, { FC } from 'react';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';

import { CategoryCard } from '@xxxlgroup/hydra-ui-components';
import { Image as ImageTypes } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';

import type { Category, CmsLink } from 'graphql-types/generated/types-generated';
import WithSmartEdit, { WithSmartEditProps } from 'cms/WithSmartEdit/WithSmartEdit';
import { useCategoryTracking } from 'cms/hooks/useCategoryTracking';
import Link from 'components/WebshopLink';

export interface CmsCategoryNavigationComponentProps extends WithSmartEditProps {
  /** Category that gives the url */
  category?: Category;
  /** Sets the id for the component */
  id: string;
  /** Image of the category */
  image: ImageTypes;
  /** Fully rendered sizes for the image */
  imageSizes?: string;
  /** Allows single column for mobile view */
  isSingleColumnAllowed?: boolean;
  /** Object of sublinks, if category does not have image/url, the last sublink determines it */
  links?: CmsLink[];
  /** variant of the component => compact or full-sized card */
  navigationCardView?: 'DEFAULT' | 'COMPACT';
  /** Whenever the image spans over 2 columns, it will become rectangular (instead of square) */
  // eslint-disable-next-line react/boolean-prop-naming
  rectangular?: boolean;
  /** Title of the component */
  title: string;
  /** Current page code */
  cmsPageCode?: string | null;
}

const CmsCategoryNavigationComponent: FC<CmsCategoryNavigationComponentProps> = (props) => {
  const {
    category,
    id,
    image,
    imageSizes,
    links,
    navigationCardView = 'DEFAULT',
    rectangular = false,
    isSingleColumnAllowed = false,
    title,
    cmsPageCode,
  } = props;
  const isDense = navigationCardView === 'COMPACT';
  const { categoryLinks, trackingOptions } = useCategoryTracking({
    category,
    cmsPageCode,
    isDense,
    links,
  });

  if (!category) {
    return null;
  }

  return (
    <CategoryCard
      key={id}
      isDense={isDense}
      title={title}
      items={categoryLinks}
      image={image}
      rectangular={rectangular}
      isSingleColumnAllowed={isSingleColumnAllowed}
      imageSizes={imageSizes}
      link={
        <Link
          href={category.url}
          data-purpose="categoryList.mainCategory.link.image"
          data-track-id="redirectSubSelector"
          title={title}
          {...trackingOptions}
        />
      }
    />
  );
};

export default track(tagComponent('CmsCategoryNavigationComponent'))(
  WithSmartEdit(CmsCategoryNavigationComponent),
);
