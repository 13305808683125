import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import track from 'react-tracking';
import { Accordion, Collapsible } from '@xxxlgroup/hydra-ui-components';
import ComponentMapper from 'cms/ComponentMapper';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import styles from 'cms/CmsAccordionsComponentContainer/CmsAccordionsComponentContainer.scss';

interface Component {
  uid: string;
  accordionTitle: string;
  anchorId?: string;
}

interface CmsAccordionsComponentContainerProps {
  /** Array of AccordionComponentContainer components that will be rendered  */
  components: Array<Component>;
  /** Index of expanded collapsible */
  activeAccordionIndexes?: Array<number>;
  /** Optional tab title is passed if rendered inside a CmsTabsComponentContainer */
  tabTitle?: string;
}

const componentType = 'CmsAccordionsComponentContainer';

export const CmsAccordionsComponentContainer = (props: CmsAccordionsComponentContainerProps) => {
  const { components, activeAccordionIndexes, tabTitle = null } = props;

  const { hash } = useLocation();
  const tracking = useTracking(props, componentType);

  const handleToggle = useCallback(
    (questionId: string) => (event: React.MouseEvent | React.KeyboardEvent, open: boolean) => {
      const openState = open ? 'open' : 'closed';
      tracking(event, { state: openState, questionId, tabTitle });
    },
    [tabTitle, tracking],
  );

  const checkIsOpen = useCallback(
    (idx: number, anchId: string | undefined) =>
      hash?.slice(1) === anchId ? true : activeAccordionIndexes?.includes(idx),
    [activeAccordionIndexes, hash],
  );

  return (
    <Accordion>
      {components.map((comp, idx) => {
        const { uid, accordionTitle, anchorId } = comp;
        return (
          <Collapsible
            data-purpose={`${componentType}.collapsible.${uid}`}
            defaultOpen={checkIsOpen(idx, anchorId)}
            className={styles.accordion}
            activator={accordionTitle}
            key={uid}
            anchorId={anchorId}
            onToggle={handleToggle(uid)}
          >
            <ComponentMapper content={comp} />
          </Collapsible>
        );
      })}
    </Accordion>
  );
};

export default track(tagComponent(componentType))(WithSmartEdit(CmsAccordionsComponentContainer));
