import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import ComponentMapper from 'cms/ComponentMapper';
import Layout from 'components/Layout';
import AnchorBar from 'components/AnchorBar';
import LinkItem from 'cms/CmsSubNavigationContainer/components/LinkItem';
import ChipItem from 'cms/CmsSubNavigationContainer/components/ChipItem';
import useMessage from 'components/Message/useMessage';

import styles from 'cms/CmsSubNavigationContainer/CmsSubNavigationContainer.scss';
import getGridImageSizes from 'utils/getGridImageSizes';

const variants = {
  GRID: 'SUB_NAVIGATION_GRID',
  IMAGE: 'IMAGE',
  CHIP: 'CHIP',
  ANCHORS: 'ANCHOR_BAR',
  LINKS: 'TEXT_LINK',
};

const alignmentOptions = {
  LEFT: 'LEFT',
  MIDDLE: 'MIDDLE',
};

const CmsSubNavigationContainer = (props) => {
  const {
    alignmentOption,
    cmsContent,
    cmsPageCode,
    components,
    grayscaleEffect,
    id,
    images,
    mainNavigations,
    imageSizes,
    rowSize,
    subNavigationVariant,
  } = props;
  const { pathname } = useLocation();
  const [showMoreText] = useMessage(['subnaviagtion.container.show.more.anchor']);

  const [firstLetter, ...otherLetters] = alignmentOption;
  const alignmentOptionText = `align${firstLetter}${otherLetters.join('').toLowerCase()}`;

  if (subNavigationVariant === variants.GRID) {
    return (
      <Layout variant="full" grid={`grid-${rowSize}`}>
        {mainNavigations.map((item) => (
          <ComponentMapper
            key={`cm-${item.uuid}`}
            content={{
              ...item,
              key: item.id,
              cmsPageCode,
              imageSizes: imageSizes || getGridImageSizes(1, rowSize, true),
            }}
          />
        ))}
      </Layout>
    );
  }

  if (subNavigationVariant === variants.IMAGE) {
    return (
      <Layout
        variant="full"
        className={classnames(styles.grid, styles[`grid-${rowSize}`], styles.isImageGrid)}
        data-testid="cms.subNavigationContainer.imageVariant"
      >
        {images.map((item) => (
          <ComponentMapper
            key={`cm-${item.id}`}
            content={{
              ...item,
              aspectRatio: '3:2',
              className: classnames({ [styles.isGrayscale]: grayscaleEffect }),
              key: item.id,
              cmsPageCode,
              imageSizes: imageSizes || getGridImageSizes(1, rowSize, true),
            }}
          />
        ))}
      </Layout>
    );
  }

  if (subNavigationVariant === variants.CHIP) {
    return (
      <ul
        className={classnames(styles.chipsSubNavigation, {
          [styles[alignmentOptionText]]: alignmentOption,
        })}
        data-testid="chipItemList"
      >
        {components.map((item) => {
          if (item.url) {
            return (
              <li key={item.id} className={styles.listChipItem}>
                <ChipItem item={item} />
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  }

  if (subNavigationVariant === variants.ANCHORS) {
    return (
      <AnchorBar anchors={components} classNameAnchorBar={styles.anchorbar} i18n={{ showMoreText }}>
        {cmsContent.map((item) => {
          const uid = item.id || item.uid;
          return (
            <ComponentMapper
              key={`cm-${uid}`}
              content={{ ...item, key: uid, id: uid, cmsPageCode }}
            />
          );
        })}
      </AnchorBar>
    );
  }

  return (
    <ul
      key={id}
      className={classnames(styles.linkSubNavigation, {
        [styles[alignmentOptionText]]: alignmentOption,
      })}
      data-testid="linkItemList"
    >
      {components.map((item) => (
        <li className={styles.subLinkItem} key={item.id}>
          <LinkItem key={item.id} item={item} isActive={pathname === item.url} />
        </li>
      ))}
    </ul>
  );
};

CmsSubNavigationContainer.propTypes = {
  /** Sets alignment for text link- & chip variants */
  alignmentOption: PropTypes.oneOf(Object.values(alignmentOptions)),
  /** Includes array of sub components for AnchorBar variant */
  cmsContent: PropTypes.arrayOf(PropTypes.shape({})),
  /** CMS page code */
  cmsPageCode: PropTypes.string.isRequired,
  /** Includes all sub components  */
  components: PropTypes.arrayOf(PropTypes.shape({})),
  /** Sets hover effect behaviour */
  // eslint-disable-next-line react/boolean-prop-naming
  grayscaleEffect: PropTypes.bool,
  /** Sets the id for the component */
  id: PropTypes.string.isRequired,
  /** Includes all images  */
  images: PropTypes.arrayOf(PropTypes.shape({})),
  /** Sets image sizes for grid variants */
  imageSizes: PropTypes.string,
  /** Includes all navigation components  */
  mainNavigations: PropTypes.arrayOf(PropTypes.shape({})),
  /** Sets number of columns per row for */
  rowSize: PropTypes.oneOf([null, 2, 3, 4, 5]),
  /** Sets variant of navigation  */
  subNavigationVariant: PropTypes.oneOf(Object.values(variants)),
};

CmsSubNavigationContainer.defaultProps = {
  alignmentOption: 'MIDDLE',
  cmsContent: [],
  components: [],
  grayscaleEffect: false,
  images: [],
  imageSizes: null,
  mainNavigations: [],
  rowSize: null,
  subNavigationVariant: 'TEXT_LINK',
};

export default track(tagComponent('CmsSubNavigationContainer'))(
  WithSmartEdit(memo(CmsSubNavigationContainer)),
);
