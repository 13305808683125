import React, { useMemo } from 'react';
import type { Category, CmsLink } from 'graphql-types/generated/types-generated';
import Link from 'components/WebshopLink';
import { useCategoryContext } from 'pages/CategoryView/provider/Category.provider';

export const useCategoryTracking = ({
  category,
  cmsPageCode,
  isDense,
  links,
  mainNavigationLink,
  promotionParentId,
  promotionParentName,
}: {
  category?: Category;
  cmsPageCode?: string | null;
  isDense: boolean;
  links?: CmsLink[];
  mainNavigationLink?: CmsLink;
  promotionParentId?: string;
  promotionParentName?: string;
}) => {
  const { category: contextCategory } = useCategoryContext();

  const currentCategory = useMemo(() => {
    const currentPageCode = cmsPageCode?.slice(cmsPageCode?.lastIndexOf('-') + 1);

    return {
      currentCategoryCode: contextCategory?.code ?? currentPageCode,
      currentCategoryName:
        contextCategory?.name ??
        category?.breadcrumbs?.find(({ itemCode }) => itemCode === currentPageCode)?.name ??
        cmsPageCode?.split('-')?.[0],
    };
  }, [cmsPageCode, contextCategory, category]);

  const categoryLinks = useMemo(
    () =>
      !isDense && links
        ? links.map((item) => {
            const clickedCategoryCode =
              item.category?.code ?? category?.code ?? item.contentPage?.code;

            return (
              <Link
                data-purpose="navigation.navitem.link"
                href={item.url}
                key={item.code}
                target={item.target}
                {...currentCategory}
                clickedCategoryCode={clickedCategoryCode}
                clickedCategoryName={
                  (item.category ?? category)?.breadcrumbs?.find(
                    ({ itemCode }) => itemCode === clickedCategoryCode,
                  )?.name ?? item.linkName
                }
                promotionParentId={promotionParentId}
                promotionParentName={promotionParentName}
                trackingData={category ? {} : { id: item.uid }}
              >
                {item.linkName}
              </Link>
            );
          })
        : [],
    [currentCategory, isDense, links, category, promotionParentId, promotionParentName],
  );

  return {
    categoryLinks,
    trackingOptions: {
      ...currentCategory,
      clickedCategoryCode: category?.code ?? mainNavigationLink?.contentPage?.code,
      clickedCategoryName:
        category?.breadcrumbs?.find(({ itemCode }) => itemCode === category?.code)?.name ??
        mainNavigationLink?.linkName,
    },
  };
};
