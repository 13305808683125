import { Heading } from '@xxxlgroup/hydra-ui-components';
import PropTypes from 'prop-types';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import { getLevel, SMALLEST_FONTSIZE, SMALLEST_SEO } from 'utils/cmsUtils/cmsUtils';
import React, { useMemo } from 'react';

export const CmsHeadingComponent = ({ anchorId, id, style, tag, title }) => {
  const fontSize = useMemo(
    () => (style?.fontSize ? getLevel(style?.fontSize, SMALLEST_FONTSIZE) : SMALLEST_FONTSIZE),
    [style?.fontSize],
  );
  let seoLevel = true;
  if (tag === 'DIV') {
    seoLevel = false;
  } else if (tag) {
    seoLevel = `h${getLevel(tag, SMALLEST_SEO)}`;
  }

  return <Heading anchorId={anchorId} level={fontSize} content={title} key={id} SEO={seoLevel} />;
};

CmsHeadingComponent.propTypes = {
  /** Creates a named anchor link to this component. */
  anchorId: PropTypes.string,
  /** Sets the id for the component */
  id: PropTypes.string.isRequired,
  /** Styling of the component, including font size of the title, ranging from H1 to H7 in Backoffice, but only H1 to H4 used on new frontend */
  style: PropTypes.shape({
    fontSize: PropTypes.oneOf(['HEADLINE1', 'HEADLINE2', 'HEADLINE3', 'HEADLINE4']),
  }).isRequired,
  /** Level of the heading tag, for SEO purpose, H1 to H6 and DIV used on old frontend, only H1 to H4 and DIV are used on new frontend */
  tag: PropTypes.oneOf(['H1', 'H2', 'H3', 'H4', 'DIV']),
  /** Title of component */
  title: PropTypes.string.isRequired,
};

CmsHeadingComponent.defaultProps = {
  anchorId: null,
  tag: null,
};

export default WithSmartEdit(CmsHeadingComponent);
