import React, { FC } from 'react';
import { useTracking } from 'utils/tracking/hooks';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import { TYPES } from 'components/ProductSlider/ProductSlider';
import PeeriusProvider from 'cms/CmsProductCellContainer/components/PeeriusProvider';
import GenericProvider from 'cms/CmsProductCellContainer/components/GenericProvider';
import VisualRecommendationsProvider from 'cms/CmsProductCellContainer/components/VisualRecommendationsProvider';

import { CmsProductCellContainerProps } from 'cms/CmsProductCellContainer/CmsProductCellContainer.types';

import styles from 'cms/CmsProductCellContainer/CmsProductCellContainer.scss';

const CmsProductCellContainer: FC<CmsProductCellContainerProps> = (props) => {
  const {
    anchorId = '',
    campaign: campaignId,
    className,
    cmsPageCode,
    code,
    excludeSameProduct = true,
    id,
    imageSizes,
    lastSeenProductsSlider = false,
    linkText,
    maxShown,
    minShown = 1,
    name,
    productCode,
    productProvider,
    products = [],
    representationType = TYPES.REGULAR,
    resultsLimit = 20,
    similarity = 0.78,
    title = '',
    uniqueProductOnly = true,
    url,
  } = props;

  const tracking = useTracking(props, 'CmsProductCellContainer');

  const commonProps = {
    campaignId,
    id,
    imageSizes,
    minShown,
    representationType,
    title,
  };

  if (productProvider === 'PEERIUS') {
    return (
      <PeeriusProvider
        {...commonProps}
        cmsPageCode={cmsPageCode}
        lastSeenProductsSlider={lastSeenProductsSlider}
        products={products}
      />
    );
  }

  if (productProvider === 'AI_VISUAL_RECOS') {
    return (
      <VisualRecommendationsProvider
        {...commonProps}
        anchorId={anchorId}
        code={code}
        excludeSameProduct={excludeSameProduct}
        productCode={productCode ?? ''}
        recommendationId={name}
        resultsLimit={resultsLimit}
        similarity={similarity}
        skeletonClassName={styles.skeleton}
        sliderClassName={className}
        uniqueProductOnly={uniqueProductOnly}
      />
    );
  }

  return (
    <GenericProvider
      {...commonProps}
      anchorId={anchorId}
      cmsPageCode={cmsPageCode}
      code={code}
      linkText={linkText}
      maxShown={maxShown}
      productCode={productCode ?? ''}
      productProvider={productProvider}
      products={products}
      propsShowLink={!!props.showLink}
      recommendationId={name}
      tracking={tracking}
      url={url}
    />
  );
};

export default track(tagComponent('CmsProductCellContainer'))(
  WithSmartEdit(CmsProductCellContainer),
);
