import React, { useEffect, useState, useMemo, ReactNode } from 'react';
import { config } from '@xxxlgroup/hydra-config';

interface HeroProviderProps {
  children: ReactNode;
}

export const CmsHeroComponentContext = React.createContext({ isDesktopView: false });

const HeroProvider = ({ children }: HeroProviderProps) => {
  const mobileBreakPoint = config.breakpoints.sm;
  const [isDesktopView, setIsDesktopView] = useState(false);
  const listener = (event: MediaQueryListEvent) => {
    setIsDesktopView(event.matches);
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(min-width: ${mobileBreakPoint}px)`);
    setIsDesktopView(mediaQuery.matches);
    mediaQuery.addEventListener('change', listener);
    return () => {
      mediaQuery.removeEventListener('change', listener);
    };
  }, [mobileBreakPoint]);

  const value = useMemo(() => ({ isDesktopView }), [isDesktopView]);

  return (
    <CmsHeroComponentContext.Provider value={value}>{children}</CmsHeroComponentContext.Provider>
  );
};

export default HeroProvider;
