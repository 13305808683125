import React from 'react';
import PropTypes from 'prop-types';
import { Types } from 'types';
import track from 'react-tracking';
import classnames from 'classnames';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import { openCookieModal } from 'utils/openCookieModal';

import Link from 'components/WebshopLink';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import * as icons from '@xxxlgroup/hydra-icons';
import LogoButton from 'components/LogoButton';
import useBrandLogo from 'cms/CmsFooterRibbonComponent/useBrandLogo';
import styles from 'cms/CmsFooterRibbonComponent/CmsFooterRibbonComponent.scss';

const CmsFooterRibbonComponent = ({ socialLinks, footerLinks, copyrightText }) => {
  const tracking = useTracking({ socialLinks, footerLinks }, 'CmsFooterRibbonComponent');
  const trackClick = (event) => {
    tracking(event);
  };

  const { icon, variant } = useBrandLogo();

  const renderSocialLinks = () =>
    socialLinks?.map((link) => {
      const { uid, url, target, iconName } = link;
      const [iconStyle, iconClassName] = pseudoIcon(icons[iconName]);
      return (
        <li key={uid} className={styles.socialLinkItem}>
          <Link
            href={url}
            onClick={trackClick}
            className={classnames(styles.socialLink, iconClassName)}
            target={target}
            style={iconStyle}
            data-purpose="footer.socialLinks"
            ariaLabel={iconName}
          />
        </li>
      );
    });

  const renderFooterLinks = () =>
    footerLinks?.map((link) => {
      const handleClick = (event) => {
        trackClick(event);
        openCookieModal(event, link.url);
      };
      const { uid, url, target, linkName } = link;

      return (
        <li key={uid} className={styles.linkWrapper}>
          <Link href={url} target={target} className={styles.link} onClick={handleClick}>
            {linkName}
          </Link>
        </li>
      );
    });

  return (
    <div className={styles.ribbonWrapper} data-testid="cmsFooterRibbon">
      <div className={styles.ribbonContent}>
        <LogoButton
          icon={icon}
          linkClassName={styles.railLink}
          iconClassName={classnames(styles.railLogo, variant && styles.variant)}
          dataPurpose="footer.railLogo"
        />
        <div className={styles.copyrightText}>{copyrightText}</div>
        <ul className={styles.footerLinks}>{renderFooterLinks()}</ul>
        <ul className={styles.socialLinks}>{renderSocialLinks()}</ul>
      </div>
    </div>
  );
};

CmsFooterRibbonComponent.propTypes = {
  /** Copyright text, e.g. © XXXLutz 2020 */
  copyrightText: PropTypes.string.isRequired,
  /** Links inside the footer */
  footerLinks: PropTypes.arrayOf(Types.link),
  /** Social Media icons inside the footer */
  socialLinks: PropTypes.arrayOf(Types.link),
};

CmsFooterRibbonComponent.defaultProps = {
  footerLinks: [],
  socialLinks: [],
};

export default track(tagComponent('CmsFooterRibbonComponent'))(
  WithSmartEdit(CmsFooterRibbonComponent),
);
