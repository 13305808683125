import { gql } from '@apollo/client';
import { productCampaignAndListingData } from 'graphql-fragments/fragments';

export const CAMPAIGNS_QUERY = gql`
  ${productCampaignAndListingData}
  query campaigns($cmsPageCode: String!, $productCode: String, $include: Boolean!) {
    getFHCampaigns(cmsPageCode: $cmsPageCode, productCode: $productCode) @include(if: $include) {
      restType
      type
      id
      fhPreview {
        showPreviewButton
        url
      }
      campaigns {
        restType
        id
        title
        moreItemsLink
        provider
        widgetId
        recommendationId
        searchResult {
          pagination {
            totalResults
          }
        }
        products {
          id
          type
          ... on Product {
            baseProduct
            ...productCampaignAndListingData
          }
          ... on Campaign {
            title
            searchResult {
              searchResults {
                columnSpan
                type
                links {
                  url
                  alternativeText
                  openInNewWindow
                }
                media {
                  altText
                  fileType
                  seoText
                  url
                  cdnFilename
                  realFilename
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const AI_RECOMMENDATIONS_QUERY = gql`
  ${productCampaignAndListingData}
  query getAIRecommendations(
    $productCode: String!
    $recommendationsParams: AIRecommendationsParams
  ) {
    getAIRecommendations(productCode: $productCode, recommendationsParams: $recommendationsParams) {
      restType
      products {
        id
        type
        source
        originalresponse
        ... on Product {
          baseProduct
          orderData {
            buyable
          }
          ...productCampaignAndListingData
        }
      }
    }
  }
`;
