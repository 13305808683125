import React from 'react';
import { Button, Content, Expander, Heading } from '@xxxlgroup/hydra-ui-components';
import { isObjectEmpty } from '@xxxlgroup/hydra-utils/common';
import { circleQuestionmark, speechBubbleFull } from '@xxxlgroup/hydra-icons';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from 'cms/CmsContactBoxComponent/CmsContactBoxComponent.scss';
import WithSmartEdit from 'cms/WithSmartEdit';
import WithImpressionTracker from 'components/WithImpressionTracker';
import Link from 'components/WebshopLink';
import Message from 'components/Message';
import Service from 'components/Service';
import { Types } from 'types';

export const CmsContactBoxComponent = ({
  alignment,
  anchorId,
  content,
  headingTag,
  iconColor,
  isPromotion,
  link,
  linkComponents,
  mobileOption,
  title,
  variant,
}) => {
  const renderExpanderVariant = () => {
    const className = classNames(styles.button, {
      [styles[`align-${alignment.toLowerCase()}`]]: alignment,
    });
    const level = headingTag ? headingTag.toLowerCase().match(/\d/) : 3;

    return (
      <div className={className}>
        <Heading anchorId={anchorId} level={Math.min(level, 4)}>
          {title}
        </Heading>
        <Message
          code={['wxs.category.filter.button.showMore', 'wxs.category.filter.button.showLess']}
        >
          {([showMore, showLess]) => (
            <Expander
              i18n={{
                showMore,
                showLess,
              }}
              variant="text"
            >
              <Content className={styles.content} content={content} />
            </Expander>
          )}
        </Message>
      </div>
    );
  };

  const renderTextLinksVariant = () =>
    linkComponents && (
      <Service
        iconColor={iconColor}
        isPromotion={isPromotion}
        anchorId={anchorId}
        title={title}
        titleTag={headingTag}
        list={linkComponents}
        variant={mobileOption}
      />
    );

  const renderCTAVariant = () => {
    const className = classNames(styles.button, {
      [styles[`align-${alignment.toLowerCase()}`]]: alignment,
    });
    const level = headingTag ? headingTag.toLowerCase().match(/\d/) : 3;

    return (
      <div className={className}>
        <Heading anchorId={anchorId} level={Math.min(level, 4)}>
          {title}
        </Heading>
        <Content className={styles.content} content={content} />
        {!isObjectEmpty(link) && (
          <Link
            trackingData={{
              purpose: 'cmsContactBoxComponent.cta.variant',
              component: 'CmsContactBoxComponent',
            }}
            as={<Button variant="secondary" />}
            target={link.target}
            href={link.url}
          >
            {link.linkName}
          </Link>
        )}
      </div>
    );
  };

  const renderContactBoxService = () => (
    <Message
      code={[
        'wxs.contactbox.twovariant.contact.title',
        'wxs.contactbox.twovariant.contact.info',
        'wxs.contactbox.twovariant.contact.url',
        'wxs.contactbox.twovariant.help.title',
        'wxs.contactbox.twovariant.help.info',
        'wxs.contactbox.twovariant.help.url',
      ]}
    >
      {([contactTitle, contactInfo, contactUrl, helpTitle, helpInfo, helpUrl]) => (
        <div className={styles.contactBoxService}>
          <p className={styles.servicesTitle}>{title}</p>
          <div className={styles.serviceArea}>
            <div className={styles.service}>
              <Link
                data-purpose="customerService.button"
                trackingData={{
                  component: 'CmsContactBoxComponent',
                }}
                as={
                  <Button
                    className={styles.serviceButton}
                    variant="secondary"
                    type="button"
                    glyphAfter={speechBubbleFull}
                  />
                }
                href={contactUrl}
              >
                {contactTitle}
              </Link>
              <p className={styles.serviceLegal}>{contactInfo}</p>
            </div>
            <div className={styles.service}>
              <Link
                data-purpose="help.button"
                href={helpUrl}
                trackingData={{
                  component: 'CmsContactBoxComponent',
                }}
                as={
                  <Button
                    className={styles.serviceButton}
                    variant="tertiary"
                    type="button"
                    glyphAfter={circleQuestionmark}
                  />
                }
              >
                {helpTitle}
              </Link>
              <p className={styles.serviceLegal}>{helpInfo}</p>
            </div>
          </div>
        </div>
      )}
    </Message>
  );

  switch (variant) {
    case 'expander':
      return renderExpanderVariant();
    case 'services':
      return renderTextLinksVariant();
    case 'with_cta':
      return renderCTAVariant();
    case 'two_buttons':
      return renderContactBoxService();
    default:
      return renderExpanderVariant();
  }
};

CmsContactBoxComponent.propTypes = {
  /** Alignment of the heading */
  alignment: PropTypes.string,
  /** Creates a named anchor link to this component. */
  anchorId: PropTypes.string,
  /** Content of the component */
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.node])),
  ]),
  /** Tag of the heading to be displayed */
  headingTag: PropTypes.string,
  /** Color of the box icon */
  iconColor: PropTypes.string,
  /** Flag to determine if the component is for promotional purposes */
  isPromotion: PropTypes.bool,
  /** Link that is shown when with_cta variant is used */
  link: PropTypes.oneOfType([Types.link]),
  /** Object of sublinks components */
  linkComponents: PropTypes.arrayOf(PropTypes.shape({})),
  /** Variant for Service Component */
  mobileOption: PropTypes.oneOf(['SLIDER', 'LIST']),
  /** Title of the component */
  title: PropTypes.string,
  /** Variant of the contact box to be displayed */
  variant: PropTypes.oneOf(['expander', 'services', 'with_cta', 'two_buttons']),
};

CmsContactBoxComponent.defaultProps = {
  alignment: 'LEFT',
  anchorId: null,
  content: null,
  headingTag: 'H1',
  iconColor: 'primary',
  isPromotion: false,
  link: {},
  linkComponents: [],
  mobileOption: 'SLIDER',
  title: null,
  variant: 'expander',
};

export default track(tagComponent('CmsContactBoxComponent'))(
  WithSmartEdit(WithImpressionTracker(CmsContactBoxComponent)),
);
