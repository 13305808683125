import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { handleSetFocus } from 'cms/CmsSubNavigationContainer/CmsSubNavigationContainer.utils';
import Link from 'components/WebshopLink';
import { Chip } from '@xxxlgroup/hydra-ui-components';
import * as icons from '@xxxlgroup/hydra-icons';
import styles from 'cms/CmsSubNavigationContainer/components/ChipItem/ChipItem.scss';

interface ItemProps {
  iconName: string;
  image?: {
    url?: string;
  };
  linkName: string;
  target: string;
  url: string;
}

interface ChipItemProps {
  item: ItemProps;
}

const ChipItem = ({ item: { iconName, image, linkName, target, url } }: ChipItemProps) => {
  const { pathname, search } = useLocation();
  const handleOnClick = useCallback(() => {
    handleSetFocus(url, pathname, search);
  }, [pathname, search, url]);

  return (
    <Link href={url} target={target} className={styles.chip} onClick={handleOnClick}>
      <Chip content={linkName} glyph={icons[iconName]} image={image?.url} />
    </Link>
  );
};

export default ChipItem;
