import React, { FC } from 'react';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import Layout from 'components/Layout';
import ComponentMapper from 'cms/ComponentMapper';

import styles from 'components/Layout/Layout.scss';

type CmsComponentProperties = {
  /* the id of the component */
  id: string;
  /* the type of the component */
  restType: string;
  /* the columns of the component */
  columns: number;
};

export interface CmsTeaserGridContainerProps {
  /** columns of grid */
  columns?: number;
  /** Includes all sub components  */
  components?: CmsComponentProperties[];
  /** Type of grid  */
  gridType: string;
}
export const CmsTeaserGridContainer: FC<CmsTeaserGridContainerProps> = ({
  columns = null,
  components,
  gridType,
}) => {
  let count = 0;
  let endingColumns = 0;
  const autoFillRow = (dedicatedColumns: number) =>
    !dedicatedColumns || (columns && dedicatedColumns > columns);

  /** The renderTeaserRowComponents try to process the components as a row,
   * it automatically tries to fill rest space gaps if invalid or to large values are provided */
  const renderTeaserRowComponents = (component: CmsComponentProperties) => {
    const rowColumns = component.columns;

    if (columns && rowColumns && count + rowColumns <= columns) {
      count += rowColumns;
      return true;
    }
    if (columns && autoFillRow(rowColumns) && count < columns) {
      endingColumns = columns - count;
      count = columns;
      return true;
    }
    return false;
  };

  const renderSubComponent = (component: CmsComponentProperties) => (
    <Layout
      key={component.id}
      variant="full"
      margin="none"
      className={
        styles[`columns${autoFillRow(component.columns) ? endingColumns : component.columns}`]
      }
    >
      <ComponentMapper content={component} />
    </Layout>
  );

  const subComponents = components?.filter(renderTeaserRowComponents).map(renderSubComponent);
  // Using gridType for Grid width of CMSTeaserGridComponent. Getting the first digit in a string.
  // Type casting to make sure that the required type is ensured
  const gridWidth = Math.min(Number(gridType.match(/\d/)), 5) as 0 | 1 | 2 | 3 | 4 | 5;

  if (subComponents) {
    return (
      <Layout className={styles.teaserGrid} variant="full" margin="none" grid={`grid-${gridWidth}`}>
        {subComponents}
      </Layout>
    );
  }

  return null;
};

export default WithSmartEdit(CmsTeaserGridContainer);
