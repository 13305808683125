import React, { useCallback } from 'react';
import classnames from 'classnames';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { useLocation } from 'react-router-dom';
import { handleSetFocus } from 'cms/CmsSubNavigationContainer/CmsSubNavigationContainer.utils';
import Link from 'components/WebshopLink';
import styles from 'cms/CmsSubNavigationContainer/components/LinkItem/LinkItem.scss';

interface ItemProps {
  url: string;
  target: string;
  linkName: string;
}

interface LinkItemProps {
  item: ItemProps;
  isActive?: boolean;
}

const LinkItem = ({ item: { url, target, linkName }, isActive = false }: LinkItemProps) => {
  const { pathname, search } = useLocation();
  const handleOnClick = useCallback(() => {
    handleSetFocus(url, pathname, search);
  }, [pathname, search, url]);

  return (
    <Link
      as={
        <HydraLink
          className={classnames(styles.linkComponent, { [styles.active]: isActive })}
          typography="small"
          theme="dark"
          layout="block"
          underline
        />
      }
      href={url}
      target={target}
      onClick={handleOnClick}
    >
      {linkName}
    </Link>
  );
};

export default LinkItem;
