import { parseHashValue } from 'utils/url';

export function handleSetFocus(url: string, pathname: string, search: string) {
  const hasAnchorIdOnSamePage = url?.substring(0, url.indexOf('#') + 1) === `${pathname}${search}#`;
  if (hasAnchorIdOnSamePage) {
    const anchorId = parseHashValue(url);
    const heading = document.getElementById(anchorId);
    setTimeout(() => {
      heading?.setAttribute('tabindex', '-1');
      heading?.focus();
    }, 100);
  }
}
