import React from 'react';
import PropTypes from 'prop-types';
import CmsContactBoxComponent from 'cms/CmsContactBoxComponent';
import classnames from 'classnames';

import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import ComponentMapper from 'cms/ComponentMapper';
import useMessage from 'components/Message/useMessage';
import LegalText from 'components/Footer/components/LegalText';

import styles from 'cms/CmsFooterCombinedBoxComponent/CmsFooterCombinedBoxComponent.scss';

export const CmsFooterCombinedBoxComponent = (props) => {
  const { contactTitle, paymentOptions, paymentsTitle, trustmarks, trustmarksTitle } = props;
  const legalText = useMessage('wxs.footercombinedbox.legaltext.description');
  const optionImageSize = 200;

  return (
    <div className={styles.componentWrapper}>
      <div className={styles.contactBox} data-testid="cmsFooterCombinedBox.Contact">
        <CmsContactBoxComponent title={contactTitle} variant="two_buttons" />
      </div>
      <div className={styles.trustmarksWrapper}>
        {trustmarks.length > 0 && <span className={styles.sectionTitle}>{trustmarksTitle}</span>}
        <div
          className={classnames(styles.trustmarkOptions, styles.options)}
          data-testid="cmsFooterCombinedBox.Trustmark"
        >
          {trustmarks &&
            trustmarks.map((trustmark) => (
              <div className={classnames(styles.trustmark, styles.option)} key={trustmark.uid}>
                <ComponentMapper
                  content={{
                    ...trustmark,
                    useSrcSet: false,
                    width: optionImageSize,
                    height: optionImageSize,
                  }}
                />
              </div>
            ))}
        </div>
      </div>
      <div className={styles.paymentsWrapper} data-testid="cmsFooterCombinedBox.Payments">
        {paymentOptions.length > 0 && <span className={styles.sectionTitle}>{paymentsTitle}</span>}
        <div className={classnames(styles.paymentOptions, styles.options)}>
          {paymentOptions &&
            paymentOptions.map((option) => (
              <div className={classnames(styles.payment, styles.option)} key={option.uid}>
                <ComponentMapper
                  content={{
                    ...option,
                    useSrcSet: false,
                    width: optionImageSize,
                    height: optionImageSize,
                  }}
                />
              </div>
            ))}
        </div>
      </div>
      <LegalText text={legalText} />
    </div>
  );
};

CmsFooterCombinedBoxComponent.propTypes = {
  contactTitle: PropTypes.string.isRequired,
  paymentOptions: PropTypes.arrayOf(PropTypes.shape({})),
  paymentsTitle: PropTypes.string.isRequired,
  trustmarks: PropTypes.arrayOf(PropTypes.shape({})),
  trustmarksTitle: PropTypes.string.isRequired,
};

CmsFooterCombinedBoxComponent.defaultProps = {
  paymentOptions: [],
  trustmarks: [],
};

export default WithSmartEdit(CmsFooterCombinedBoxComponent);
