import React from 'react';
import { TYPES } from 'components/ProductSlider/ProductSlider';
import {
  FredhopperCampaignsData,
  GetFHCampaignDataType,
  GetRepresentationType,
  ParseProductsType,
} from 'cms/CmsProductCellContainer/CmsProductCellContainer.types';
import { CampaignDataType } from 'graphql-types/generated/types-generated';

import RepresentationSlider from './components/RepresentationSlider';
import RepresentationGrid from './components/RepresentationGrid';

const { LIST } = TYPES;

export const parseProducts: ParseProductsType = (
  products,
  recommendationId,
  campaignId,
  provider = '',
) => {
  const isParsed = products?.some((product) => product?.recommendationId && product?.provider);

  if (isParsed) {
    return products;
  }

  const useCampaignId = ['PEERIUS', 'PEERIUS_RELATED', 'ASYNC'].includes(provider);
  const parsedRecomendationId = useCampaignId && campaignId;
  const parsedProvider = provider === 'ASYNC' && 'fredhopper';

  return products?.map((product) => ({
    ...product,
    recommendationId: parsedRecomendationId || recommendationId,
    provider: parsedProvider || provider,
  }));
};

export const getRepresentation = ({
  anchorId,
  code,
  fHCampaignData,
  id,
  imageSizes,
  lastItemOverlay,
  representationType,
}: GetRepresentationType) =>
  representationType === LIST ? (
    <RepresentationGrid
      anchorId={anchorId}
      campaignTitle={fHCampaignData.campaignTitle}
      products={fHCampaignData.finalCampaignProducts}
    />
  ) : (
    <RepresentationSlider
      anchorId={anchorId}
      code={code}
      imageSizes={imageSizes}
      id={id}
      lastItemOverlay={lastItemOverlay}
      representationType={representationType}
      sliderTitle={fHCampaignData.campaignTitle}
      products={fHCampaignData.productList}
      isHeadingShown={false}
    />
  );

const getCampaignProducts = ({
  currentCampaign,
  campaignId,
}: {
  currentCampaign?: CampaignDataType;
  campaignId?: string;
}) =>
  currentCampaign?.products?.map((product) => ({
    ...product,
    recommendationId: campaignId,
    widgetId: currentCampaign.widgetId,
    provider: currentCampaign.provider,
  }));

export const getFHCampaignData: GetFHCampaignDataType = ({
  campaignId,
  data,
  maxShown,
  minShown,
  products,
  propsShowLink,
  title,
  url,
}) => {
  const campaignsData = data?.getFHCampaigns ?? {};
  const currentCampaign = campaignsData.campaigns?.find((campaign) => campaign.id === campaignId);
  const campaignProducts = getCampaignProducts({ currentCampaign, campaignId });
  const finalCampaignProducts = campaignProducts || products || [];

  if (finalCampaignProducts.length < minShown || !finalCampaignProducts.length) {
    return null;
  }

  const campaignMoreItemsLink = currentCampaign?.moreItemsLink ?? '';
  const campaignTitle = title || currentCampaign?.title || '';
  const href = url || campaignMoreItemsLink;
  const showLink = !!href && propsShowLink;
  const showHeader = !!title || showLink;
  const totalResults = currentCampaign?.searchResult?.pagination?.totalResults ?? 0;
  const productList = maxShown ? finalCampaignProducts.slice(0, maxShown) : finalCampaignProducts;

  return {
    campaignsData,
    campaignTitle,
    href,
    showLink,
    showHeader,
    totalResults,
    finalCampaignProducts,
    productList,
  };
};

export const isShowMoreButtonVisible = (
  productProvider: string,
  fHCampaignData: FredhopperCampaignsData,
) =>
  productProvider === 'ASYNC'
    ? !!fHCampaignData.showLink && fHCampaignData.totalResults > fHCampaignData.productList.length
    : !!fHCampaignData.showLink;
