import React from 'react';

import Paragraph from 'components/Paragraph';
import WithSmartEdit from 'cms/WithSmartEdit';

import styles from 'cms/CmsIconParagraphComponent/CmsIconParagraphComponent.scss';

const CmsIconParagraphComponent = (props) => (
  <Paragraph {...props} contentClassName={styles.content} titleClassName={styles.title} />
);

CmsIconParagraphComponent.propTypes = Paragraph.propTypes;

export default WithSmartEdit(CmsIconParagraphComponent);
