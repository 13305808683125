import React, { FC } from 'react';
import PeeriusRecos from 'components/PeeriusRecos';
import { RepresentationTypes } from 'cms/CmsProductCellContainer/CmsProductCellContainer.types';
import { Product } from 'graphql-types/generated/types-generated';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';

interface PeeriusProviderProps {
  campaignId?: string;
  cmsPageCode?: string;
  id: string;
  imageSizes?: string;
  lastSeenProductsSlider: boolean;
  minShown: number;
  products: Product[];
  representationType: RepresentationTypes;
  title: string;
}

const PeeriusProvider: FC<PeeriusProviderProps> = ({
  campaignId,
  cmsPageCode,
  id,
  imageSizes,
  lastSeenProductsSlider,
  minShown,
  products,
  representationType,
  title,
}) => {
  if (!isArrayEmpty(products)) {
    return null;
  }

  return (
    <PeeriusRecos
      area={campaignId}
      className=""
      cmsPageCode={cmsPageCode}
      componentOverwrites={{ lastSeenProductsSlider, representationType }}
      id={id}
      imageSizes={imageSizes}
      minShown={minShown}
      title={title}
    />
  );
};

export default PeeriusProvider;
