import React from 'react';
import classNames from 'classnames';

import Paragraph, { ParagraphProps } from 'components/Paragraph/Paragraph';
import WithSmartEdit, { WithSmartEditProps } from 'cms/WithSmartEdit/WithSmartEdit';

import styles from 'cms/CmsParagraphComponent/CmsParagraphComponent.scss';

export interface CmsParagraphComponentProps extends ParagraphProps, WithSmartEditProps {}

const CmsParagraphComponent = ({ contentClassName, ...rest }: CmsParagraphComponentProps) => (
  <Paragraph {...rest} contentClassName={classNames(styles.wrappedContent, contentClassName)} />
);

export default WithSmartEdit(CmsParagraphComponent);
