import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { Carousel } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import ComponentMapper from 'cms/ComponentMapper';
import WithSmartEdit from 'cms/WithSmartEdit';
import useMessage from 'components/Message/useMessage';
import { tagComponent } from 'utils/tracking/tracking';
import { useImpressionTracker } from 'utils/tracking/hooks';
import SkipSection from 'components/SkipSection';
import styles from 'cms/CmsSuperBillboardSliderContainer/CmsSuperBillboardSliderContainer.scss';
import classNames from 'classnames';

const CmsSuperBillboardSliderContainer = (props) => {
  const { id, components, name, restType, title, isLooped, animationSpeed, aboveTheFold } = props;
  const section = useMessage('accessibility.skipSection.list', {}, true);

  const trackedProps = useMemo(() => ({ id, name, restType, title }), [id, name, restType, title]);

  const { setRef, tracking } = useImpressionTracker(
    trackedProps,
    'CmsSuperBillboardSliderContainer',
  );

  const [goTo, next, previous] = useMessage([
    'wxs.slider.goto',
    'wxs.slider.arrow.nextPicture',
    'wxs.slider.arrow.previousPicture',
  ]);

  const onPageChange = useCallback(
    (event, oldIndex, newIndex) => {
      if (event) {
        tracking(this, event, { newIndex, oldIndex });
      }
    },
    [tracking],
  );

  const onSliderRefChange = useCallback(
    (ref) => {
      setRef(ref?.sliderRef?.current);
    },
    [setRef],
  );

  if (isArrayEmpty(components)) {
    return null;
  }

  return (
    <SkipSection id={id} section={section} title={title}>
      <Carousel
        autoPlay
        fullWidth
        arrowAttributes={{ size: 'small' }}
        i18n={{
          goTo,
          next,
          previous,
        }}
        isLooped={isLooped}
        animationSpeed={animationSpeed.toLowerCase()}
        onSlideChanged={onPageChange}
        className={styles.slider}
        classNameContainer={styles.container}
        classNameArrow={classNames(styles.arrow, styles.fadeIn)}
        data-purpose="fullWidth.imageSlider"
        ref={onSliderRefChange}
        sneakPeekDisabled={false}
      >
        {components.map((component, index) => (
          <ComponentMapper
            key={component.id}
            content={{ ...component, key: component.id, index }}
            aboveTheFoldOverride={aboveTheFold}
          />
        ))}
      </Carousel>
    </SkipSection>
  );
};

CmsSuperBillboardSliderContainer.propTypes = {
  // eslint-disable-next-line react/boolean-prop-naming
  aboveTheFold: PropTypes.bool,
  /** Defines the amount of time between two transitions (in ms) */
  animationSpeed: PropTypes.oneOf(['SLOW', 'FAST']),
  /** Includes all sub components  */
  components: PropTypes.arrayOf(PropTypes.shape({})),
  /** Sets the id for the component */
  id: PropTypes.string.isRequired,
  /** Contains CMS component's type */
  restType: PropTypes.string.isRequired,
  /** Contains CMS component's system name */
  name: PropTypes.string,
  /** Contains CMS component's title */
  title: PropTypes.string,
  /** Activates endless sliding */
  isLooped: PropTypes.bool,
};

CmsSuperBillboardSliderContainer.defaultProps = {
  animationSpeed: 'SLOW',
  components: [],
  name: null,
  title: null,
  isLooped: true,
};

export default track(tagComponent('CmsSuperBillboardSliderContainer'))(
  WithSmartEdit(CmsSuperBillboardSliderContainer),
);
