import React from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { Card, CardOverlay, CardImage, Content } from '@xxxlgroup/hydra-ui-components';
import withLinkModifier from '@xxxlgroup/hydra-ui-components/dist/common/molecules/Card/hoc/withLinkModifier';
import { tagComponent } from 'utils/tracking/tracking';
import { useImpressionTracker } from 'utils/tracking/hooks';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import Link from 'components/WebshopLink';
import styles from 'cms/CmsTextImageComponent/CmsTextImageComponent.scss';
import classnames from 'classnames';

let cssClassArray;
const filterLastItem = (classes, query) => {
  const filter =
    classes &&
    classes.filter((el) => el.toLowerCase().indexOf(query.toLowerCase()) > -1).slice(-1)[0];

  return filter ? filter.match(/[^-]*$/)[0] : null;
};

const getLinkText = (element) => !!element;

const getTrackingData = (data) => {
  const { uid, isPromotion, link, name, restType, itemType, title, tracking } = data;
  const { rootCategory } = tracking ? tracking.getTrackingData() : false;
  const trackingTitle = link && link.text.find(getLinkText) ? link.text.find(getLinkText) : title;
  const pageName = typeof document !== 'undefined' ? document.title : null;

  const trackingData = {
    id: uid,
    component: restType,
    name,
    category: trackingTitle,
    page_name: pageName,
    purpose: 'navigation.content.card',
    isPromotion,
    is_promotion: isPromotion,
    title: trackingTitle,
    itemType,
  };

  return rootCategory
    ? {
        category: rootCategory,
        'category-level1': trackingTitle,
        ...trackingData,
      }
    : { category: trackingTitle, ...trackingData };
};

const mapBackgroundCard = (data, overlay, imageHeight, trackingRef) => {
  const title = !(
    !data.title && !(data.link && data.link.text && data.link.text.find(getLinkText))
  );
  const hasLink = !!(data.link && data.link.url);

  const renderOverlay = () => (
    <CardOverlay
      hasLink={hasLink}
      key={`cm-overlay-${data.uuid || data.id}`}
      variant={filterLastItem(cssClassArray, 'card-overlay') || overlay}
    >
      {data.link?.text?.find(getLinkText) || data.title}
    </CardOverlay>
  );

  const trackingData = getTrackingData(data);

  // Remove the page_name property since its different client / server, which causes rehydration issues
  delete trackingData.page_name;
  delete trackingData.is_promotion;

  const aboveTheFold = data?.aboveTheFold;

  return (
    <div
      ref={trackingRef}
      className={classnames(styles.wrapper, { [styles.imageComponentCropped]: imageHeight })}
      key={`cm-${data.uuid || data.id}`}
      data-testid="cmsTextImageComponent"
    >
      <Card
        key={`cm-${data.uuid || data.id}`}
        alignment={filterLastItem(data.cssClass?.split(' '), 'card-align')}
        className={styles.textImageComponent}
        hasBorder={filterLastItem(cssClassArray, 'card-border')}
        overlay={title && renderOverlay()}
        renderLink={
          data?.link && withLinkModifier(Link)({ href: data.link?.url, ...getTrackingData(data) })
        }
        target={data.target || '_self'}
        theme={filterLastItem(cssClassArray, 'card-theme')}
        variant="image"
      >
        {data.image && (
          <CardImage
            fetchPriority={aboveTheFold ? 'high' : undefined}
            loading={aboveTheFold ? 'eager' : 'lazy'}
            isBackground
            imageSizes={data.imageSizes}
            source={data.image}
          />
        )}
      </Card>
      {data.legalText && <Content className={styles.legalText} content={data.legalText} />}
    </div>
  );
};

export const CmsTextImageComponent = (props) => {
  const { barColor, cssClass, variant, toggleHeight } = props;
  cssClassArray = cssClass?.split(' ');

  const { setRef } = useImpressionTracker(
    {
      ...props,
      ...getTrackingData(props),
    },
    'useImpressionTracker(CmsTextImageComponent)',
  );

  switch (variant) {
    case 'CARD':
      return mapBackgroundCard(props, barColor, toggleHeight, setRef);
    case 'CARDOVERLAY':
      return mapBackgroundCard(props, 'text', toggleHeight, setRef);
    default:
      return mapBackgroundCard(props, 'text', toggleHeight, setRef);
  }
};

CmsTextImageComponent.propTypes = {
  /** possible bar colors for 'card' variant  */
  barColor: PropTypes.PropTypes.oneOf(['bar', 'barSecondary', 'barPromo1']),
  /** add additional css class  */
  cssClass: PropTypes.string,
  /** Flag to determine if the component is used as promotion */
  isPromotion: PropTypes.bool,
  /** possible text image variants  */
  variant: PropTypes.string,
  /** sets the height */
  toggleHeight: PropTypes.bool,
};

CmsTextImageComponent.defaultProp = {
  barColor: 'barSecondary',
  cssClass: null,
  isPromotion: false,
  variant: null,
  toggleHeight: false,
};

export default track(tagComponent('CmsTextImageComponent'))(WithSmartEdit(CmsTextImageComponent));
