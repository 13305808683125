import React from 'react';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import PropTypes from 'prop-types';
import ComponentMapper from 'cms/ComponentMapper';

export const CmsAccordionComponentContainer = ({ components }) => (
  <>
    {components &&
      components.map((component) => <ComponentMapper key={component.id} content={component} />)}
  </>
);

CmsAccordionComponentContainer.propTypes = {
  /** Array of Cms components that will be rendered  */
  components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default WithSmartEdit(CmsAccordionComponentContainer);
