import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Image, Types } from '@xxxlgroup/hydra-ui-components';
import styles from 'cms/CmsHeroComponent/components/Stamp/Stamp.scss';

const Stamp = ({ className, stamp, variant, loading }) => (
  <div
    className={classnames(className, { [styles.isBanner]: variant === 'banner' })}
    data-purpose="stamp.image"
  >
    <Image
      source={{ ...stamp, targetFormat: 'png' }}
      useSrcSet
      width={300}
      className={styles.stamp}
      loading={loading}
    />
  </div>
);

Stamp.propTypes = {
  stamp: PropTypes.oneOfType([PropTypes.string, Types.image]).isRequired,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string,
  loading: PropTypes.string,
};

Stamp.defaultProps = {
  className: null,
};

export default Stamp;
