import React, { FC, Fragment, RefObject } from 'react';

import Campaigns from 'components/Listing/components/ProductTileGrid/components/Campaigns';
import Divider from 'components/Listing/components/Divider';
import Message from 'components/Message';
import ProductTile from 'components/ProductTile';

import type {
  ListingBarPagination,
  SearchResultsData,
} from 'components/Listing/types/Listing.types';
import type {
  CmsTextImageComponentWsDTO,
  CmsImageComponentWsDTO,
} from 'components/Listing/components/ProductTileGrid/components/Campaigns/Campaigns.types';
import type { Product } from 'graphql-types/generated/types-generated';

import styles from 'cms/CmsProductCellContainer/components/RepresentationTileGrid/RepresentationTileGrid.scss';

type PageAttributesType = { [key: string]: string | number };
type ProductData = SearchResultsData | CmsTextImageComponentWsDTO | CmsImageComponentWsDTO;

interface RepresentationTileGridProps {
  /** ref on first productTile / a11y focus */
  firstTileRef?: RefObject<HTMLAnchorElement>;
  /** Define if the RepresentationTileGrid is loaded for hotdeals or not */
  isHotdeals?: boolean;
  /** used for tracking purposes */
  mode?: string;
  /** get the pagination data object to display listing page info */
  pagination?: ListingBarPagination;
  /** Array of products ( and campaigns) */
  products: ProductData[];
}

const isProduct = (item: ProductData): item is Product => item.type === 'productWsDTO';

const isCmsCampaignComponent = (
  item: ProductData,
): item is CmsImageComponentWsDTO | CmsTextImageComponentWsDTO =>
  ['cmsImageComponentWsDTO', 'cmsTextImageComponentWsDTO'].includes(item.type ?? '');

const RepresentationTileGrid: FC<RepresentationTileGridProps> = (props) => {
  const renderTile = (
    product: SearchResultsData,
    pageAttributes: PageAttributesType,
    firstTileRef: RefObject<HTMLAnchorElement> | null,
    isHotdeals: boolean,
  ) => {
    if (isProduct(product)) {
      const { id, mediaData } = product;
      const filteredMediaDataPresentation = mediaData?.presentation?.filter(
        (media) => media.fileType === 'IMAGE',
      );
      const filteredMediaData = {
        ...mediaData,
        presentation: filteredMediaDataPresentation,
      };

      return (
        <ProductTile
          key={id}
          {...product}
          className={{
            representationTileGrid: styles.representationTileGrid,
          }}
          hideFavourite={isHotdeals}
          mediaData={filteredMediaData}
          tileRef={firstTileRef}
          {...pageAttributes}
        />
      );
    }
    return Campaigns(product, pageAttributes);
  };

  const { products = [], pagination, firstTileRef = null, isHotdeals = false } = props;
  const { pageSize, totalResults } = pagination || {};

  return (
    <>
      {products.map((item, index) => {
        let pageAttributes: PageAttributesType = {};
        const { id, firstItemOfPage } = item ?? {};
        const uniqueId = (isCmsCampaignComponent(item) ? item.uuid : null) ?? id ?? index;
        const firstItemOfPageNum = firstItemOfPage ?? 0;
        const loadedProducts = (pageSize ?? 1) * (firstItemOfPageNum - 1);

        const dividerValues: Record<string, number | string> = {
          loaded: loadedProducts,
          total: totalResults ?? 0,
        };
        const dividerId = `divider-${firstItemOfPageNum - 1}`;

        if (firstItemOfPage) {
          pageAttributes = {
            'data-page': firstItemOfPageNum,
            'data-purpose': 'representationTileGrid.firstProductPerPage',
          };
        }

        const product = {
          ...item,
          itemPosition: index,
          listLength: totalResults,
        };

        return (
          <Fragment key={uniqueId}>
            {firstItemOfPageNum > 1 && (
              <Message code="wxs.listing.divider.info" values={dividerValues}>
                {(info) => <Divider content={info.toString()} dividerId={dividerId} />}
              </Message>
            )}
            {renderTile(product, pageAttributes, firstTileRef, isHotdeals)}
          </Fragment>
        );
      })}
    </>
  );
};

export default RepresentationTileGrid;
