import React from 'react';
import WithSmartEdit from 'cms/WithSmartEdit';
import PropTypes from 'prop-types';
import ComponentMapper from 'cms/ComponentMapper';
import Layout from 'components/Layout';

export const CmsTabComponentContainer = ({ components, cmsPageCode, tabTitle }) => (
  <>
    {components?.map((comp) => (
      <Layout variant="full" margin="content" data-testid="tab-container" key={`tab${comp.uid}`}>
        <ComponentMapper content={{ ...comp, cmsPageCode, tabTitle }} />
      </Layout>
    ))}
  </>
);

CmsTabComponentContainer.propTypes = {
  /** Array of cms components that will present Tab content  */
  components: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Code for cms page  */
  cmsPageCode: PropTypes.string.isRequired,
  /** Title of this tab */
  tabTitle: PropTypes.string.isRequired,
};

export default WithSmartEdit(CmsTabComponentContainer);
