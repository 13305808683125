import React from 'react';
import PropTypes from 'prop-types';
import Iframe from 'components/Iframe';

const IFRAME_DEFAULT_HEIGHT = 600;

const CmsIntegrationComponent = ({
  title,
  tool: { targetUrl, fixedHeight, scrolling, extraPadding, allowTag, loading },
}) => (
  <Iframe
    targetUrl={targetUrl}
    title={title}
    fixedHeight={fixedHeight ?? IFRAME_DEFAULT_HEIGHT}
    extraPadding={extraPadding}
    shouldHaveScrolling={scrolling}
    allow={allowTag}
    loadingType={loading}
  />
);

CmsIntegrationComponent.propTypes = {
  /** title of iframe */
  title: PropTypes.string.isRequired,
  /** Iframe CMS configuration options */
  tool: PropTypes.shape({
    targetUrl: PropTypes.string,
    fixedHeight: PropTypes.number,
    scrolling: PropTypes.bool,
    extraPadding: PropTypes.number,
    allowTag: PropTypes.string,
    loading: PropTypes.oneOf(['eager', 'lazy']),
  }).isRequired,
};

export default CmsIntegrationComponent;
