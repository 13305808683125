import React, { FocusEventHandler, MouseEventHandler } from 'react';
import { Button as HydraButton } from '@xxxlgroup/hydra-ui-components';
import { arrowRight } from '@xxxlgroup/hydra-icons';
import Link from 'components/WebshopLink/WebshopLink';
import styles from 'cms/CmsHeroComponent/components/Button/Button.scss';
import classNames from 'classnames';

interface ButtonProps {
  button: {
    style: 'primary' | 'secondary' | 'tertiary';
    target: string;
    text: string;
    url: string;
    isInverted: boolean;
  };
  onClick: MouseEventHandler<HTMLButtonElement>;
  onHover: MouseEventHandler<HTMLButtonElement> | FocusEventHandler<HTMLButtonElement>;
  className?: string;
}

const Button = ({ button, onClick, onHover, className }: ButtonProps) => (
  <Link
    as={
      <HydraButton
        inverted={button.isInverted}
        glyphAfter={arrowRight}
        size="large"
        variant={button.style}
      />
    }
    className={classNames(styles.button, className)}
    data-purpose="teaser.button"
    href={button.url}
    onClick={onClick}
    onFocus={onHover}
    onMouseEnter={onHover}
    target={button.target}
  >
    {button.text}
  </Link>
);

export default Button;
