import { logo, logoContrast, logoContrastFirstVariantCZSK } from '@xxxlgroup/hydra-icons';
import useFeatureFlags from 'hooks/useFeatureFlags';

const useBrandLogo = () => {
  const [contrast, variant] = useFeatureFlags([
    'poseidon.footer.logo.contrast',
    'poseidon.footer.logo.contrast.variant',
  ]);

  let icon = logo;
  if (contrast) {
    icon = variant ? logoContrastFirstVariantCZSK : logoContrast;
  }

  return {
    icon,
    variant,
  };
};

export default useBrandLogo;
