import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@xxxlgroup/hydra-ui-components';
import { plus } from '@xxxlgroup/hydra-icons';
import ContentExtended from 'components/ContentExtended';
import styles from 'cms/CmsHeroComponent/components/Promotion/Promotion.scss';

const Promotion = ({ element }) => (
  <div className={styles.promotion}>
    {/* Due to background styling, no dom savings, no svg exchange */}
    <Icon glyph={plus} className={styles.plus} />
    <ContentExtended className={styles.promotionText} content={element} />
  </div>
);

Promotion.propTypes = {
  element: PropTypes.string.isRequired,
};

export default Promotion;
