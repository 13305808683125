import React, { EventHandler, FC, MouseEventHandler, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { Link as HydraLink } from '@xxxlgroup/hydra-ui-components';
import { xMenu } from '@xxxlgroup/hydra-icons';

import styles from 'cms/CmsFlyoutComponent/components/FlyoutBanner/FlyoutBanner.scss';

const FlyoutBanner: FC<{
  ariaLabel?: string;
  /** Function to close Flyout for current session */
  hideBanner: MouseEventHandler;
  /** id to connect flyout interactivity for assistive technologies */
  id: string;
  /** Passed value, if Flyout is expanded (only mobile) */
  isExpanded: boolean;
  /** Status of the dismissable button */
  isDismissable?: boolean;
  /** Content of the banner component */
  text?: string;
  /** Headline of the banner component */
  title?: string;
  /** Open/close flyout */
  toggle?: EventHandler<SyntheticEvent<HTMLButtonElement>>;
}> = ({
  ariaLabel = 'close',
  hideBanner,
  id,
  isDismissable = true,
  isExpanded,
  text,
  title,
  toggle,
}) => (
  <header
    className={classNames(styles.container, {
      [styles.withoutText]: !text,
    })}
  >
    <button
      className={styles.flyoutBannerWrapperMobile}
      onClick={toggle}
      aria-expanded={isExpanded}
      aria-controls={id}
    >
      {text || title}
    </button>
    <div className={styles.flyoutBannerWrapperDesktop}>{text || title}</div>
    {isDismissable && (
      <HydraLink
        aria-label={ariaLabel}
        className={styles.close}
        data-purpose="banner.closeButton"
        glyphAfter={xMenu}
        onClick={hideBanner}
      />
    )}
  </header>
);

export default FlyoutBanner;
