import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Image, Button, Types } from '@xxxlgroup/hydra-ui-components';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import * as icons from '@xxxlgroup/hydra-icons';

import Link from 'components/WebshopLink/WebshopLink';
import WithSmartEdit from 'cms/WithSmartEdit/WithSmartEdit';
import { openCookieModal } from 'utils/openCookieModal';

import styles from 'cms/CmsLinkComponent/CmsLinkComponent.scss';

export const CmsLinkComponent = ({
  iconName,
  image,
  linkName,
  linkStyle,
  seoData,
  target,
  url,
}) => {
  const getNoFollow = seoData?.noFollow || false;
  const [iconStyle, iconClassName] = pseudoIcon(icons[iconName]);

  const getCookieModal = useCallback((event) => openCookieModal(event, url), [url]);
  const renderImage = () => <Image className={styles.image} source={image} loading="eager" />;

  const renderTextLink = () => (
    <Link
      className={classNames(styles.link, iconClassName, {
        [styles.iconImageLink]: !!image,
        [styles.svgLink]: iconName && !image,
      })}
      href={url}
      target={target}
      noFollow={getNoFollow}
      onClick={getCookieModal}
      style={iconStyle}
    >
      {image && renderImage()}
      <p
        className={classNames(styles.linkName, {
          [styles.iconImageLinkName]: !!image,
        })}
        data-testid="cms.link"
      >
        {linkName}
      </p>
    </Link>
  );

  const renderButtonLink = () => {
    const buttonIcon = icons[iconName] || icons.arrowRight;
    return (
      <Link
        as={<Button variant="tertiary" glyphAfter={buttonIcon} noFollow={getNoFollow} />}
        target={target}
        href={url}
      >
        {linkName}
      </Link>
    );
  };

  return linkStyle === 'BUTTON' ? renderButtonLink() : renderTextLink();
};

CmsLinkComponent.propTypes = {
  /** Icon Name */
  iconName: PropTypes.string,
  /** Link image */
  image: Types.image,
  /** Linkname */
  linkName: PropTypes.string.isRequired,
  /** Link style defines whether the link is being rendered as text or button */
  linkStyle: PropTypes.oneOf(['TEXT', 'BUTTON']),
  /** Seo data */
  seoData: PropTypes.shape({
    noFollow: PropTypes.bool,
  }),
  /** Link target handles if the link is internal (React Router) or external */
  target: PropTypes.oneOf(['_self', '_blank']).isRequired,
  /** Link url */
  url: PropTypes.string.isRequired,
};

CmsLinkComponent.defaultProps = {
  iconName: '',
  image: null,
  linkStyle: 'TEXT',
  seoData: null,
};

export default WithSmartEdit(CmsLinkComponent);
