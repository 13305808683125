import React, { FC, memo } from 'react';
import { Image as ImageType } from '@xxxlgroup/hydra-ui-components/dist/common/types/typeDefinitions';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';

import { CategoryCard } from '@xxxlgroup/hydra-ui-components';

import type { CmsLink } from 'graphql-types/generated/types-generated';
import WithSmartEdit, { WithSmartEditProps } from 'cms/WithSmartEdit/WithSmartEdit';
import Link from 'components/WebshopLink/WebshopLink';
import WithImpressionTracker from 'components/WithImpressionTracker';
import { useCategoryTracking } from 'cms/hooks/useCategoryTracking';

interface CmsMainNavigationComponentProps extends WithSmartEditProps {
  /** Sets the id for the component */
  id: string;
  /** Image for component */
  image: ImageType;
  /** Overwrite image sizes for the the image within the component */
  imageSizes?: string;
  /** Flag to determine if any (Moebelix) special styling overrides CategoryCard isDense implementation */
  isDense?: boolean;
  /** Flag to determine if the component is used as promotion */
  isPromotion?: boolean;
  /** Allows single column for mobile view */
  isSingleColumnAllowed?: boolean;
  /** Links that are provided to navigation */
  links?: CmsLink[];
  /** Main url for image and title */
  mainNavigationLink?: CmsLink;
  /** Name of the Cms component */
  name: string;
  /** Contains Main Navigation variant */
  navigationVariant?: 'SUBNAVIGATION' | 'MAIN_NAVIGATION' | 'IMAGE';
  /** Whenever the image spans over 2 columns, it will become rectangular (instead of square) */
  rectangular?: boolean;
  /** Type of the Cms component */
  restType: string;
  /** Title of the component */
  title?: string;
  /** UID of the Cms component */
  uid: string;
}

const CmsMainNavigationComponent: FC<CmsMainNavigationComponentProps> = memo(
  ({
    id,
    image,
    isSingleColumnAllowed = false,
    links,
    title = '',
    mainNavigationLink,
    name,
    navigationVariant = 'MAIN_NAVIGATION',
    rectangular = false,
    imageSizes,
    isDense: isDenseCMSProp = true,
    isPromotion = false,
    restType,
    uid,
  }) => {
    const trackingData = {
      id: uid,
      component: restType,
      type: restType,
      name,
      title,
    };

    const isDense = navigationVariant === 'SUBNAVIGATION' && isDenseCMSProp;
    const { categoryLinks, trackingOptions } = useCategoryTracking({
      isDense,
      links,
      mainNavigationLink,
      ...(isPromotion && {
        promotionParentId: uid,
        promotionParentName: name,
      }),
    });

    return (
      <CategoryCard
        key={id}
        isDense={isDense}
        title={title}
        items={categoryLinks}
        image={image}
        imageSizes={imageSizes}
        rectangular={rectangular}
        isSingleColumnAllowed={isSingleColumnAllowed}
        link={
          mainNavigationLink ? (
            <Link
              href={mainNavigationLink?.url}
              data-purpose="categoryList.mainCategory.link.image"
              data-track-id="redirectSubSelector"
              title={title}
              target={mainNavigationLink?.target}
              isPromotion={isPromotion}
              trackingData={trackingData}
              {...trackingOptions}
            />
          ) : (
            <div data-purpose="categoryList.mainCategory.link.image" />
          )
        }
      />
    );
  },
);

export default track(tagComponent('CmsMainNavigationComponent'))(
  WithSmartEdit(WithImpressionTracker(CmsMainNavigationComponent as FC) as FC),
);
