import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardOverlay, CardImage } from '@xxxlgroup/hydra-ui-components';
import withLinkModifier from '@xxxlgroup/hydra-ui-components/dist/common/molecules/Card/hoc/withLinkModifier';
import Link from 'components/WebshopLink';
import { x as xClose } from '@xxxlgroup/hydra-icons';
import { config } from '@xxxlgroup/hydra-config';

import styles from 'cms/CmsFlyoutComponent/CmsFlyoutComponent.scss';

const renderCardOverlay = (title, disclaimer, hasLink) => (
  <CardOverlay
    key={title}
    content={disclaimer && <div className={styles.text}>{disclaimer}</div>}
    hasLink={hasLink}
  >
    {title}
  </CardOverlay>
);

const FlyoutCard = ({ disclaimer, hideBanner, i18n, link, image, text, title }) => (
  <Card
    className={styles.card}
    variant="image"
    target={link?.target}
    overlay={renderCardOverlay(title, disclaimer, !!link?.url)}
    renderLink={link?.url && withLinkModifier(Link)({ href: link.url })}
  >
    {(!text || text === '') && (
      <Button
        ariaLabel={i18n?.close}
        className={styles.flyoutClose}
        data-purpose="banner.flyout.closeButton"
        glyphAfter={xClose}
        onClick={hideBanner}
      />
    )}
    <CardImage
      className={styles.image}
      imageSizes={`(min-width: ${config.breakpoints.md}px) ${config.srcSetImageSizes[1]}px, 100vw`}
      isBackground
      source={{
        hashCode: image,
      }}
    />
  </Card>
);

FlyoutCard.propTypes = {
  /** Content of the Card Overlay */
  disclaimer: PropTypes.string,
  /** Function to close Flyout for current session */
  hideBanner: PropTypes.func.isRequired,
  /** used only for ariaLabel  */
  i18n: PropTypes.shape({
    close: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  /** Source of the image */
  image: PropTypes.string.isRequired,
  /** Link of the card component */
  link: PropTypes.shape({
    target: PropTypes.string,
    url: PropTypes.string,
  }),
  /** Content of the banner component */
  text: PropTypes.string,
  /** Headline of the banner component */
  title: PropTypes.string,
};

FlyoutCard.defaultProps = {
  disclaimer: null,
  i18n: {
    close: 'close',
  },
  link: null,
  text: null,
  title: null,
};

export default FlyoutCard;
