import React, { useEffect, useState, useRef, useCallback, useId } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import useDynamicContentService from 'hooks/useDynamicContentService';
import { hasExitTrigger as hasExitTriggerFunction } from 'utils/exitIntentUtils';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import FlyoutCard from 'cms/CmsFlyoutComponent/components/FlyoutCard';
import FlyoutBanner from 'cms/CmsFlyoutComponent/components/FlyoutBanner';
import ExitIntent from 'components/ExitIntent';
import styles from 'cms/CmsFlyoutComponent/CmsFlyoutComponent.scss';

const animationState = {
  closing: 'closing',
  closed: 'closed',
};

const CmsFlyoutComponent = (props) => {
  const { disclaimer, dismissable, image, i18n, link, text, title, trackingData, trigger } = props;

  const tracking = useTracking(props, 'CmsFlyoutComponent');
  const [interactionState, setInteractionState] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const isExitTriggered = useRef(false);
  const isViewportLargerThanMd = useMediaQuery({ largerThan: 'md' });
  const { dismissSlot } = useDynamicContentService();

  const toggle = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  const hideBanner = (event) => {
    tracking('dismissed', trackingData.impression);
    event.preventDefault();
    setInteractionState(animationState.closing);
    dismissSlot(props.id);
    setInteractionState(animationState.closed);
  };

  const hasExitTrigger = hasExitTriggerFunction(trigger);

  const triggerTracking = useCallback(
    (exitIntent) => {
      if (isExitTriggered.current === false && exitIntent) {
        tracking('shown', trackingData.impression);
        isExitTriggered.current = true;
      }
    },
    [tracking, trackingData.impression],
  );

  useEffect(() => {
    if (!hasExitTriggerFunction(trigger)) {
      tracking('shown', trackingData.impression);
    }
  }, [tracking, trackingData.impression, trigger]);

  const onKeyDown = (event) => {
    if (event.key === 'Escape') {
      hideBanner(event);
    }
  };

  const mobileFlyoutId = useId();

  return (
    <ExitIntent>
      {(exitIntent) => {
        exitIntent && triggerTracking(exitIntent);
        return (
          (!hasExitTrigger || exitIntent) && (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <article
              onKeyDown={onKeyDown}
              role={isViewportLargerThanMd ? 'dialog' : 'region'}
              className={classNames(styles.container, {
                [styles[interactionState]]: interactionState,
              })}
            >
              <FlyoutBanner
                isDismissable={dismissable}
                isExpanded={isExpanded}
                id={mobileFlyoutId}
                hideBanner={hideBanner}
                text={text}
                title={title}
                ariaLabel={i18n?.close}
                toggle={toggle}
              />
              <div
                id={mobileFlyoutId}
                className={classNames(styles.flyoutCard, { [styles.isExpanded]: isExpanded })}
              >
                <FlyoutCard
                  disclaimer={disclaimer}
                  hideBanner={hideBanner}
                  i18n={i18n}
                  link={link}
                  image={image}
                  text={text}
                  title={title}
                />
              </div>
            </article>
          )
        );
      }}
    </ExitIntent>
  );
};

CmsFlyoutComponent.propTypes = {
  /** Content of the Card Overlay */
  disclaimer: PropTypes.string,
  /** Status of the dismissable button */
  // eslint-disable-next-line react/boolean-prop-naming
  dismissable: PropTypes.bool,
  /** used only for ariaLabel  */
  i18n: PropTypes.shape({
    close: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }),
  /** Identifier for dynamicContent */
  id: PropTypes.string,
  /** Source of the image */
  image: PropTypes.string.isRequired,
  /** Link of the card component */
  link: PropTypes.shape({
    target: PropTypes.string,
    url: PropTypes.string,
  }),
  /** Content of the banner component */
  text: PropTypes.string,
  /** Headline of the banner component */
  title: PropTypes.string,
  /** Tracking Data - can be specified by vendor strough api */
  trackingData: PropTypes.shape({
    impression: PropTypes.shape({}),
    click: PropTypes.shape({}),
  }),
  /** Trigger for the exitIntent campaigns from trbo */
  trigger: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  ),
};

CmsFlyoutComponent.defaultProps = {
  disclaimer: null,
  dismissable: true,
  i18n: {
    close: 'close',
  },
  id: null,
  link: null,
  text: null,
  title: null,
  trackingData: {
    impression: {},
    click: {},
  },
  trigger: [],
};

export default track(tagComponent('CmsFlyoutComponent'))(CmsFlyoutComponent);
