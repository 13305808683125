import React, { FC } from 'react';
import { Skeleton } from '@xxxlgroup/hydra-ui-components';
import { useQuery } from '@apollo/client';
import RepresentationSlider from 'cms/CmsProductCellContainer/components/RepresentationSlider';
import { AI_RECOMMENDATIONS_QUERY } from 'cms/CmsProductCellContainer/CmsProductCellContainer.query';
import { parseProducts } from 'cms/CmsProductCellContainer/utils';
import useIsMountedOnCSR from 'hooks/useIsMountedOnCsr';

import {
  RepresentationTypes,
  VisualRecomendationQueryData,
  VisualRecomendationQueryVariables,
} from 'cms/CmsProductCellContainer/CmsProductCellContainer.types';

interface VisualRecommendationsProps {
  /** Creates a named anchor link to this component. */
  anchorId: string;
  /** Campaign id */
  campaignId?: string;
  /** Code for cms page  */
  code?: string;
  /** exclude same product from VR service */
  excludeSameProduct: boolean;
  /** Sets the id for the component */
  id: string;
  /** Override the default image sizes for the ProductCard component */
  imageSizes?: string;
  /** minimal amount of products required to show the CMSProductCellContainer */
  minShown: number;
  recommendationId?: string;
  /** Code of the Product */
  productCode: string;
  /** representation type (List or on of the three slider options: defaultSlider, extendedSlider or imageSlider) */
  representationType: RepresentationTypes;
  /** maximum products to recieve from VR service */
  resultsLimit: number;
  /** minimal product similarity parameter for VR service */
  similarity: number;
  /** styles for the skeleton component */
  skeletonClassName: string;
  /** styles for the slider component */
  sliderClassName?: string;
  /** title of component */
  title: string;
  /** get only unique products from VR service */
  uniqueProductOnly: boolean;
}

const VisualRecommendationsProvider: FC<VisualRecommendationsProps> = ({
  anchorId,
  campaignId,
  code,
  excludeSameProduct,
  id,
  imageSizes,
  minShown,
  recommendationId,
  productCode,
  representationType,
  resultsLimit,
  similarity,
  skeletonClassName,
  sliderClassName,
  title,
  uniqueProductOnly,
}) => {
  const isMountedOnCSR = useIsMountedOnCSR();

  const { loading, error, data } = useQuery<
    VisualRecomendationQueryData,
    VisualRecomendationQueryVariables
  >(AI_RECOMMENDATIONS_QUERY, {
    skip: !isMountedOnCSR,
    variables: {
      productCode,
      recommendationsParams: {
        excludeSameProduct,
        minShown,
        type: 'visual',
        resultsLimit,
        similarity,
        uniqueProductOnly,
      },
    },
  });

  if (loading) {
    return <Skeleton className={skeletonClassName} count={1} />;
  }

  if (error) {
    return null;
  }
  const products = data?.getAIRecommendations?.products;

  if (!products) {
    return null;
  }

  return (
    <RepresentationSlider
      anchorId={anchorId}
      className={sliderClassName}
      code={code}
      id={id}
      imageSizes={imageSizes}
      representationType={representationType}
      sliderTitle={title}
      products={parseProducts(products, recommendationId, campaignId, 'AI_VISUAL_RECOS')}
      isHeadingShown
    />
  );
};

export default VisualRecommendationsProvider;
