import React, { FC } from 'react';
import MediaQuery from 'components/MediaQuery';
import ComponentMapper from 'cms/ComponentMapper';
import Layout from 'components/Layout';
import { tagType } from 'components/Layout/Layout';
import getGridImageSizes from 'utils/getGridImageSizes';
import { Breakpoint } from 'components/MediaQuery/MediaQuery.types';

import styles from 'cms/CmsMainContainer/CmsMainContainer.scss';

type DefaultCmsComponentProperties = {
  /* the uid of the component */
  uid: string;
  /* the type of the component */
  restType: string;
  /* the columnSpan of the component */
  columnSpan: number;
  /* string containing image sizes */
  imageSizes: string;
  /* the type of the component */
  type: string;
};

type PartialCmsComponent = Partial<DefaultCmsComponentProperties>;

interface ChildCmsComponentProps {
  aboveTheFold: boolean;
  /* the cms component that will be rendered */
  component: PartialCmsComponent;
  /* the number of columns in the grid */
  numberOfColumns: number;
  /* the cms page code */
  cmsPageCode: string;
  /* the image sizes for the grid */
  imageSizes?: string;
  /* the index of the component in the array */
  hasOnlyNavComponents?: boolean;
  /* isFullRowComponent forces the component to be full row width */
  isFullRowComponent?: boolean;
  /* isRectangularCardsEnabled enables rectangular cards */
  isRectangularCardsEnabled?: boolean;
  /* tag for the layout component */
  tag?: tagType;
}

const imageContainingComponentTypes = [
  'CmsCategoryNavigationComponent',
  'CmsImageComponent',
  'CmsTextImageComponent',
  'CmsMainNavigationComponent',
  'CmsMainContainer',
];

const assignImageSizes = (
  component: PartialCmsComponent,
  columnSpan: number,
  numberOfColumns: number,
  hasOnlyNavComponents: boolean,
  ImageSizes?: string,
): PartialCmsComponent => {
  const isImageContainingComponent = imageContainingComponentTypes.includes(
    component.restType ?? '',
  );

  return isImageContainingComponent
    ? {
        ...component,
        imageSizes:
          ImageSizes || getGridImageSizes(columnSpan, numberOfColumns, hasOnlyNavComponents),
      }
    : component;
};

const ChildCmsComponent: FC<ChildCmsComponentProps> = ({
  aboveTheFold,
  cmsPageCode,
  component,
  numberOfColumns,
  hasOnlyNavComponents = false,
  imageSizes = undefined,
  isFullRowComponent = false,
  isRectangularCardsEnabled = false,
  tag = 'div',
}) => {
  const calculateColumnSpanForChildren = (
    desktopView: boolean,
    component: PartialCmsComponent,
    rowSpan: number,
  ) =>
    isFullRowComponent && !desktopView ? rowSpan : Math.min(component.columnSpan || 1, rowSpan);

  return (
    <MediaQuery largerThan={Breakpoint.lg} key={component.uid}>
      {(isLarger: boolean) => {
        const columnSpan = calculateColumnSpanForChildren(isLarger, component, numberOfColumns);
        const componentWithImageSizes = assignImageSizes(
          component,
          columnSpan,
          numberOfColumns,
          hasOnlyNavComponents,
          imageSizes,
        );

        const content = {
          ...componentWithImageSizes,
          cmsPageCode,
        };

        const childComponent =
          content.type === 'cmsCategoryNavigationComponentWsDTO' ||
          content.type === 'cmsMainNavigationComponentWsDTO' ? (
            React.cloneElement(<ComponentMapper content={content} />, {
              content: {
                ...content,
                isDense: !isRectangularCardsEnabled,
                rectangular: columnSpan > 1,
                isSingleColumnAllowed: hasOnlyNavComponents && !isRectangularCardsEnabled,
              },
            })
          ) : (
            <ComponentMapper aboveTheFoldOverride={aboveTheFold} content={content} />
          );

        return (
          <Layout
            margin="none"
            variant="full"
            key={component.uid}
            className={styles[`columns${columnSpan}`]}
            tag={tag}
          >
            {childComponent}
          </Layout>
        );
      }}
    </MediaQuery>
  );
};

export default ChildCmsComponent;
