import React, { FC } from 'react';
import { Button } from '@xxxlgroup/hydra-ui-components';
import Link from 'components/WebshopLink';
import useMessage from 'components/Message/useMessage';
import { FhPreview } from 'graphql-types/generated/types-generated';

interface FHPreviewButtonProps {
  fhPreview?: FhPreview | null;
}

const FHPreviewButton: FC<FHPreviewButtonProps> = ({ fhPreview }) => {
  const previewText = useMessage('wxs.fh.link.preview');

  if (!fhPreview?.showPreviewButton) {
    return null;
  }

  return (
    <Link href={fhPreview.url} target="_blank" as={<Button />}>
      {previewText}
    </Link>
  );
};

export default FHPreviewButton;
